
import {defineComponent} from "vue";
import OpeningHours from "@/components/opening-hours.vue";

export default defineComponent ({
  name: "OpeningHoursWidget",

  components: {
    OpeningHours
  },

  props: {
    widgetContent: {
      type: Object,
      default: null
    },
  },
});
