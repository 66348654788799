<template>
  <section v-if="widgetContent && sources.audioObjects && sources.audioObjects.length"
           class="tour-audio-feature ion-margin-vertical box-widget">
    <div class="ion-margin-bottom">
      <ion-text class="ion-text-center" color="tour">
        <h2 class="ion-text-uppercase">{{ widgetContent['schema:name'] || $t('tourOverview.audioFeatureHeadline') }}</h2>
      </ion-text>
      <div v-html="widgetContent['schema:description']" class="ion-text-center preline-text"></div>
    </div>
    <audio-player :sources="sources" ref="touraudio"></audio-player>
  </section>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import { IonText } from "@ionic/vue"
import AudioPlayer from '@/components/audio-player.vue';

export default defineComponent ({
  name: "AudioWidget",

  components: {
    IonText,
    AudioPlayer,
  },

  props: {
    widgetContent: {
      type: Object,
      default: null
    }
  },

  computed: {
    sources(): any {
      const data: any = {
        ...this.widgetContent
      };

      const audioItems = this.widgetContent['schema:audio']
        ? (Array.isArray(this.widgetContent['schema:audio'])
          ? this.widgetContent['schema:audio']
          : [this.widgetContent['schema:audio']])
        : []

      if (audioItems.length) {
        data.audioObjects = audioItems;
      }

      return data;
    }
  }
});
</script>

<style scoped lang="scss">

</style>
