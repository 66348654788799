
import {defineComponent} from "vue";
import {IonText} from "@ionic/vue"

export default defineComponent({
  name: "TourHeaderWidget",

  components: {
    IonText
  },

  props: {
    widgetContent: {
      type: Object,
      default: null
    },

    tourData: {
      type: Object,
    },

    media: {
      type: Object,
      default: null
    }
  },

  computed: {
    logoFilter() {
      return ((this as any).tourData || {})._primaryColorFilter || '';
    }
  }
});
