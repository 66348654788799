<template>
  <section class="tour-images" v-if="contents.length">
    <content-slider :contents="contents"
                    :title="widgetContent['schema:name'] || ''"
                    :description="widgetContent['schema:description'] || ''">
    </content-slider>
  </section>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import ContentSlider from "@/components/content-slider.vue";

export default defineComponent ({
  name: "ContentSliderWidget",

  components: {
    ContentSlider,
  },

  props: {
    widgetContent: {
      type: Object,
      default: null
    },
  },

  computed: {
    contents(): any[] {
      if (this.widgetContent['schema:image']) {
        return Array.isArray(
          this.widgetContent['schema:image']
        ) ? this.widgetContent['schema:image']
          : [this.widgetContent['schema:image']]
      }
      return [];
    }
  }
});
</script>

<style scoped lang="scss">

</style>
