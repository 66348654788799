
export function renderMarkerSVG(number = 0, color: string, colorContrast: string): string {
  // default circle marker
  let label = '<circle class="marker-text" cx="24.560104" cy="25.080395" r="15" />';

  const svgLocationMarkerText = [
    // 1
    '<path class="marker-text" d="M18,38.88V34.56h4.63v-16a27.67,27.67,0,0,1-5.26,2.49L16,18.2l8.2-5.74h3.6v22.1h3.45v4.32Z"/>',
    // 2
    '<path class="marker-text" d="M32.59,38.88h-17V35.15c1.55-1.5,4.8-4.75,6.62-6.73,3.52-3.88,4.31-6.3,4.31-8.12,0-2.73-1.46-3.56-3.28-3.56s-3.37.79-5.59,2.61L14.81,16a14.19,14.19,0,0,1,10-3.92c4.72,0,7.73,3.09,7.73,7.57,0,2.85-1.83,5.9-4.64,9-1.19,1.3-3,3.09-4.71,4.71a14.7,14.7,0,0,1-1.51,1.31,40.94,40.94,0,0,1,4.16-.2h7.92Z"/>',
    // 3
    '<path class="marker-text" d="M19.6,40.08a19.66,19.66,0,0,1-3.28-.2L15.05,36.2a13.13,13.13,0,0,0,3,.27c5.5,0,9.27-2.49,9.27-6.1a3.27,3.27,0,0,0-1.75-3,9.45,9.45,0,0,0-3.68-.47c-.67,0-1.35,0-2,0V22.49a20.83,20.83,0,0,0,2.73.16c2.93,0,4.4-1,4.4-3.13,0-1.7-1.07-3-3.37-3a9.22,9.22,0,0,0-5.86,2.22L15,15.4a15.45,15.45,0,0,1,9.35-3.32c4.95,0,8.55,2.93,8.55,6.89a6,6,0,0,1-4.08,5.82c3,1,4.71,3.25,4.71,6C33.54,36.28,27.8,40.08,19.6,40.08Z"/>',
    // 4
    '<path class="marker-text" d="M32.55,34a11.23,11.23,0,0,1-1.22-.08,4.67,4.67,0,0,1,.2,1.43v4.55l-5,.64V34.82c0-.31.07-.87.07-1a4.59,4.59,0,0,1-1.26.12H14.81v-3.6l7.65-17.94h5.46L22.3,25.79a27,27,0,0,1-1.86,3.69,8.09,8.09,0,0,1,.83-.08h4.47c.44,0,.87,0,1,0s0-.56,0-.71c0-1.11.08-3.33.28-5.27l.44-4.64,4.16-.95v7.81c0,.91,0,2.65-.12,3.76.15,0,.83,0,1.26,0h1.67V34Z"/>',
    // 5
    '<path class="marker-text" d="M20.48,40.11a12.74,12.74,0,0,1-3-.28l-1.15-3.92a14.06,14.06,0,0,0,3.72.55c4.2,0,7.17-2.37,7.17-5.78a4,4,0,0,0-4.32-4.2,18.89,18.89,0,0,0-5.78,1.11l1.27-15h13.5l-.79,4.52H23.33l-.56,5.46a8,8,0,0,1,1.86-.2c4.91,0,8.32,3.17,8.32,7.69C33,35.91,27.76,40.11,20.48,40.11Z"/>',
    // 6
    '<path class="marker-text" d="M24.87,39.49c-5.78,0-9.94-4.64-9.94-11.17A16.21,16.21,0,0,1,20.4,16.2a27.35,27.35,0,0,1,10.17-5.74l1.15,3.8c-3.24,1.3-5.94,2.33-8.16,5.19a12.19,12.19,0,0,0-2.13,4,6.59,6.59,0,0,1,4.23-1.31,8.28,8.28,0,0,1,8.48,8.36A9,9,0,0,1,24.87,39.49ZM25,26.38a8.13,8.13,0,0,0-4.12,1.3,23.27,23.27,0,0,0-.12,2.38c0,3.37,1.47,5.35,3.92,5.35s3.92-1.9,3.92-4.87C28.55,28,27.17,26.38,25,26.38Z"/>',
    // 7
    '<path class="marker-text" d="M33.07,17.24a72.06,72.06,0,0,0-3.92,6.38c-1.31,2.38-4.28,8.75-5,12.91L23.6,39.3l-5.82,1.47a74.46,74.46,0,0,1,2.85-9.51,69.66,69.66,0,0,1,3.49-8,37.52,37.52,0,0,1,3.68-6.06,6.4,6.4,0,0,1-1.58.16H15.72l1.19-4.79h17Z"/>'
  ];

  if (number && number <= 7) {
    label = svgLocationMarkerText[number-1];
  }

  const svgCode = `<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 49.12 76.49'>
<defs>
  <style>
    .primary { fill: ${color}; }
    .faded { opacity: 0.47; }
    .marker-text { fill: ${colorContrast}; }
  </style>
</defs>
<path class='primary' d='M49.12,24.39A24.15,24.15,0,0,0,24.73,0,25,25,0,0,0,0,24.74,24.18,24.18,0,0,0,3.21,37H3.15L24.44,73.06,45.71,36.85h-.09a24.65,24.65,0,0,0,3.5-12.46'/>
<path class='primary faded' d='M24.56,76.49c11.9,0,21.54-1.53,21.54-3.42s-9.64-3.42-21.54-3.42S3,71.18,3,73.07s9.64,3.42,21.54,3.42'/>
${label}
</svg>`;

  return svgCode;
}
