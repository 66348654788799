<template>
  <section class="ion-margin-vertical box-widget" v-if="videoSrc">
    <ion-text class="ion-text-center" color="tour">
      <h2 class="ion-text-uppercase">{{ widgetContent['schema:name'] || $t('tourOverview.tourTrailerHeader') }}</h2>
    </ion-text>
    <div v-html="widgetContent['schema:description']" class="ion-text-center preline-text"></div>
    <div class="ion-margin-top">
      <video-player :source="videoSrc" ref="tourteaser"></video-player>
    </div>
  </section>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import { IonText } from "@ionic/vue"
import VideoPlayer from '@/components/video-player.vue';

export default defineComponent ({
  name: "VideoWidget",

  components: {
    IonText,
    VideoPlayer,
  },

  props: {
    widgetContent: {
      type: Object,
      default: null
    }
  },

  computed: {
    videoSrc(): string {
      if (this.widgetContent['schema:video'] && this.widgetContent['schema:video']['schema:url']) {
        return this.widgetContent['schema:video']['schema:url'];
      }
      return ''
    }
  }
});
</script>

<style scoped lang="scss">

</style>
