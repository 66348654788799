
import {defineComponent, computed} from 'vue';
import {BlobContent} from "@/store-management/app-db";
import {alertController, IonButton, IonProgressBar, IonText} from "@ionic/vue";
import {mapActions, mapMutations, useStore} from "vuex";
import { uniq } from 'lodash-es';

export default defineComponent({
  name: "OfflineMode",

  components: {
    IonProgressBar,
    IonButton,
    IonText,
  },

  props: {
    regionData: {
      type: Object,
      required: true
    },
    tourData: {
      type: Object,
      required: true
    },
  },

  setup(props) {
    const store = useStore();
    const availableOfflineMedia = computed(() => ((store.state.availableOfflineMedia[props.regionData.id] || {})[props.tourData.id] || []));
    const mediaDownloadInProgress = computed(() => ((store.state.mediaDownloadInProgress[props.regionData.id] || {})[props.tourData.id] || false));

    const targetRegionOfflineMedia = computed(() => (
        uniq(props.regionData._mediaList || [])
    ));
    const targetTourOfflineMedia = computed(() => (
        uniq(props.tourData._mediaList || [])
    ));
    const targetOfflineMedia = computed(() => (
        // uniq media urls
        [...new Set(targetRegionOfflineMedia.value.concat(targetTourOfflineMedia.value))]
    ));

    const availableTourOfflineMedia = computed(() => (
        targetTourOfflineMedia.value.filter(media => availableOfflineMedia.value.includes(media))
    ));

    const availableRegionTourOfflineMedia = computed(() => (
        targetOfflineMedia.value.filter(media => availableOfflineMedia.value.includes(media))
    ));

    const unavailableOfflineMedia = computed(() => (
        targetOfflineMedia.value.filter(media => !availableOfflineMedia.value.includes(media))
    ));

    return {
      availableOfflineMedia,
      mediaDownloadInProgress,
      availableTourOfflineMedia,
      availableRegionTourOfflineMedia,
      targetTourOfflineMedia,
      targetOfflineMedia,
      unavailableOfflineMedia,
    }
  },

  mounted() {
    this.updateOfflineMediaList();
  },

  methods: {
    ...mapActions(['setMediaSource']),
    ...mapMutations(['emptyAvailableOfflineMedia', 'addAvailableOfflineMedia', 'setMediaDownloadInProgress']),
    async updateOfflineMediaList() {
      this.emptyAvailableOfflineMedia({regionId: this.regionData.id, tourId: this.tourData.id});
      this.addAvailableOfflineMedia({
        regionId: this.regionData.id,
        tourId: this.tourData.id,
        urls: await BlobContent.listBlobContents(this.regionData.id)
      });
    },
    async updateAvailableOfflineMedia() {
      if (this.mediaDownloadInProgress) {
        return;
      }

      const abortCtrl = new AbortController();

      let withFailures = false;
      this.setMediaDownloadInProgress({
        regionId: this.regionData.id,
        tourId: this.tourData.id,
        inProgress: abortCtrl,
      });

      await this.unavailableOfflineMedia.reduce(async (previousPromise, mediaURL: string) => {
        await previousPromise;
        if (abortCtrl.signal.aborted) {
          return Promise.resolve();
        }
        return BlobContent.addBlobContent(this.regionData.id, mediaURL, abortCtrl.signal)
            .then((blobContent) => {
              this.setMediaSource({
                mediaURL,
                source: blobContent,
              })
              this.addAvailableOfflineMedia({
                regionId: this.regionData.id,
                tourId: this.tourData.id,
                urls: [mediaURL],
              });
            })
            .catch((e) => {
              console.error(e);
              withFailures = true;
            });
      }, Promise.resolve());

      this.setMediaDownloadInProgress({
        regionId: this.regionData.id,
        tourId: this.tourData.id,
        inProgress: false,
      });

      if (withFailures && !abortCtrl.signal.aborted) {
        const alert = await alertController
            .create({
              header: this.$t('components.offlineMode.incompleteDownload'),
              message: this.$t('components.offlineMode.incompleteMediaDLBody'),
              buttons: [
                {
                  text: 'Okay',
                },
              ],
            });
        await alert.present();
      }
    },
    abortUpdateOfflineMedia() {
      if (this.mediaDownloadInProgress) {
        this.mediaDownloadInProgress.abort();
        this.setMediaDownloadInProgress({
          regionId: this.regionData.id,
          tourId: this.tourData.id,
          inProgress: false,
        });
      }
    }
  },
});
