<template>
  <div class="audio-player">
    <div class="top-area" v-if="showVisualizer">
      <div class="audio-bg-gradient" :class="{ 'animated': playing }">
        <div class="audio-title-wrapper ion-text-center">
          <div class="ion-padding-top">
            <AudioIcon/>
          </div>
          <div class="title ion-padding">
            {{ sources['schema:name'] }}
          </div>
        </div>
      </div>
    </div>

    <div class="audio-player-controls-wrapper">
      <ion-grid class="base-grid">
        <ion-row class="ion-align-items-center">

          <ion-col :size="[ hasSingleSrc ? 'auto': '12' ]">
            <ion-grid>
              <ion-row class="ion-justify-content-center ion-align-items-center">
                <ion-col class="ion-text-end main-control" v-if="!hasSingleSrc">
                  <PrevIcon :class="{ 'disabled': currentIndex === 0}" @click="changeIndex(-1)"
                            role="button" :aria-label="$t('components.audioPlayer.prevChapter')"/>
                </ion-col>
                <ion-col class="ion-text-center main-control play-button-wrapper">
                  <div class="play-button" :class="{ playing: playing }"
                       role="button"  :aria-label="$t('components.audioPlayer.playPause')">
                    <a v-if="!playing" v-on:click="playAudio()" title="Play/Pause"><PlayIcon /></a>
                    <a v-if="playing" v-on:click="stopAudio()" title="Play/Pause"><PauseIcon /></a>
                  </div>
                </ion-col>
                <ion-col class="ion-text-start main-control" v-if="!hasSingleSrc">
                  <NextIcon :class="{ 'disabled': currentIndex === sources.audioObjects?.length-1 }" @click="changeIndex(1)"
                            role="button"  :aria-label="$t('components.audioPlayer.nextChapter')"/>
                </ion-col>
              </ion-row>
            </ion-grid>
          </ion-col>

          <ion-col :size="[ hasSingleSrc ? '': '12' ]" :offset="[ hasSingleSrc ? '1': '0' ]" class="expand">
            <div class="progress-container" v-on:click="changeCurrentTime" ref="progressContainer">
              <div class="progress-handle" :style="{ 'left': progressHandlePosition }"></div>
              <ion-progress-bar :value="currentTime / trackDuration" color="tour"
                                :aria-label="$t('components.audioPlayer.progress')" role="progressbar">
              </ion-progress-bar>
            </div>

            <ion-grid class="sub-controls-wrapper">
              <ion-row class="ion-justify-content-start ion-align-items-center">
                <template v-if="showSubControls">
                  <ion-col class="ion-text-start">
                    <div v-on:click="showVolumeController" class="sub-control">
                      <VolumeIcon />
                      <div
                          class="volume-controller"
                          :class="{ active: VolumeControllerActive, 'fade-in': VolumeControllerFade }"
                      >
                        <div v-on:click="reinstateVolumeControllerTimer"><VolumeIcon /></div>
                        <input type="range"
                               v-model="volume"
                               min="0"
                               max="1"
                               step="0.01"
                               v-on:change="reinstateVolumeControllerTimer"
                               v-on:touchstart="cancelVolumeControllerTimer"/>
                      </div>
                    </div>
                  </ion-col>
                  <ion-col class="ion-text-start">
                    <div v-on:click="changeLoopSetting" class="sub-control" :class="{ active: loop }">
                      <LoopIcon />
                    </div>
                  </ion-col>
                  <!--
                  <ion-col class="ion-text-start">
                    <div class="sub-control">
                      random
                    </div>
                  </ion-col>
                  -->
                </template>
                <ion-col class="time-container ion-text-end">
                  <span class="current-time"> {{ timeFormat(currentTime, trackDuration) }}</span>
                </ion-col>
              </ion-row>
            </ion-grid>
          </ion-col>

          <ion-col size="12" v-if="showExternalLinks">
            <ion-grid class="external-links ion-margin-top">
              <ion-row class="ion-justify-content-start ">
                <ion-col >
                  <SpotifyIcon :class="{ 'disabled': !spotifyLink }"/>
                </ion-col>
                <ion-col >
                  <ApplePodcastsIcon :class="{ 'disabled': !applePodcastsLink }"/>
                </ion-col>
                <ion-col >
                  <SoundcloudIcon :class="{ 'disabled': !soundcloudLink }"/>
                </ion-col>
                <ion-col class="download">
                  <div>
                    <a :href="currentSource" target="_blank"><DownloadIcon /></a>
                  </div>
                </ion-col>
              </ion-row>
            </ion-grid>
          </ion-col>

        </ion-row>
      </ion-grid>

    </div>

    <div class="transcript-handler" v-if="audioTranscript">
      <transcript-trigger :content="audioTranscript" :title="sources['schema:name']"></transcript-trigger>
    </div>

    <div class="ion-text-center ion-margin-vertical ion-padding-top chapter-selection" v-if="!hasSingleSrc">
      <div class="box-widget white soft-shadow rounded" :style="{ margin: '2px' }">

        <div :class="[ 'chapter-select-toggler', { active: !chaptersCollapsed } ]">
          <div @click="switchCollapse()" role="button" :aria-label="$t('components.audioPlayer.showChapters')">
            <h3 class="ion-text-uppercase ion-text-left ion-text-center">
              <span>{{ $t('components.audioPlayer.chapterOverview') }}</span>
              <span :class="['collapse-toggle ion-margin-left', { active: !chaptersCollapsed }]">
              <ChevronDown></ChevronDown>
            </span>
            </h3>
          </div>
        </div>

        <div class="transition-anchor-slide">
          <transition-group name="slidedown">
            <div class="chapter-content" v-if="!chaptersCollapsed" role="menu">
              <div v-for="(chapter, key) in sources.audioObjects"
                   :key="'chapter'+key"
                   class="chapter-item">
                <ion-button @click="changeIndex(0, key, true)"
                            color="tour"
                            class="chapter-label large"
                            expand="block"
                            role="menuitem"
                            :aria-label="(chapter && chapter['schema:name']) || $t('components.audioPlayer.chapterPlaceholder') + ' ' + (key+1)"
                            shape="round" :disabled="currentIndex === key">
                  {{ (chapter && chapter['schema:name']) || $t('components.audioPlayer.chapterPlaceholder') + ' ' + (key+1) }}
                </ion-button>
              </div>
            </div>
          </transition-group>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
import { defineComponent} from 'vue';
import { padStart } from 'lodash-es';
import { mapState } from 'vuex';
import { IonCol, IonRow, IonGrid, IonProgressBar, IonButton } from '@ionic/vue';

import TranscriptTrigger from '@/components/transcript.vue'

import AudioIcon from '@/assets/svg/audioplayer/audio.vue';
import PlayIcon from '@/assets/svg/audioplayer/play.vue';
import PauseIcon from '@/assets/svg/audioplayer/pause.vue';
import PrevIcon from '@/assets/svg/audioplayer/previous.vue';
import NextIcon from '@/assets/svg/audioplayer/next.vue';
import VolumeIcon from '@/assets/svg/audioplayer/volume.vue';
import LoopIcon from '@/assets/svg/audioplayer/loop.vue';
import DownloadIcon from '@/assets/svg/audioplayer/download.vue';
import SpotifyIcon from '@/assets/svg/audioplayer/spotify.vue';
import ApplePodcastsIcon from '@/assets/svg/audioplayer/applepodcasts.vue';
import SoundcloudIcon from '@/assets/svg/audioplayer/soundcloud.vue';
import ChevronDown from "@/assets/svg/chevron-down";

export default defineComponent({
  name: 'AudioPlayer',

  components: {
    IonGrid,
    IonCol,
    IonRow,
    IonProgressBar,
    IonButton,
    TranscriptTrigger,
    AudioIcon,
    PlayIcon,
    PauseIcon,
    PrevIcon,
    NextIcon,
    VolumeIcon,
    LoopIcon,
    DownloadIcon,
    SpotifyIcon,
    ApplePodcastsIcon,
    SoundcloudIcon,
    ChevronDown
  },

  props: {
    showSubControls: {
      type: Boolean,
      default: false,
    },
    showExternalLinks: {
      type: Boolean,
      default: false,
    },
    showVisualizer: {
      type: Boolean,
      default: false
    },
    sources: {
      type: Object,
      required: true,
    },
  },

  mounted() {
    if (this.sources.audioObjects?.length) {
      this.currentIndex = 0;
      this.currentSource = this.media[this.sources.audioObjects[this.currentIndex]['schema:url']];
      this.spotifyLink = this.sources.spotifyLink || '';
      this.soundcloudLink = this.sources.soundcloudLink || '';
      this.applePodcastsLink = this.sources.applePodcastsLink || '';

      // initialization, no autoplay
      this.changeAudioObject(false)
    }
  },

  computed: {
    ...mapState(['media', 'routeCounter']),
    hasSingleSrc() {
      return this.sources.audioObjects?.length <= 1;
    },
    audioTranscript() {
      return (this.sources.audioObjects || [])
          .filter(audio => (audio && audio['schema:transcript']))
          .map(audio => (`<strong>${audio['schema:name'] ? audio['schema:name'] + '\n' : ''}</strong>${audio['schema:transcript']}`))
          .join('\n\n');
    },
  },

  data() {
    return {
      // metadata
      title: '',
      soundcloudLink: '',
      applePodcastsLink:  '',
      spotifyLink: '',

      // current chapter selection and audio object
      currentIndex: 0,
      currentSource: '',
      audio: null,

      // current audio object state
      currentTime: 0,
      checkingCurrentPositionInTrack: null,
      loop: false,
      playing: false,
      trackDuration: 0,
      progressHandlePosition: '-4px',

      // volume control animation and state
      VolumeControllerTimerFade: null,
      VolumeControllerFade: false,
      VolumeControllerTimerVisibility: null,
      VolumeControllerActive: false,
      volume: 100,

      // chapter select
      chaptersCollapsed: true,
    };
  },

  methods: {
    switchCollapse() {
      this.chaptersCollapsed = !this.chaptersCollapsed;
    },

    changeIndex(direction, directInput) {
      if (directInput > -1) {
        this.currentIndex = directInput;
        this.currentSource = this.media[this.sources.audioObjects[directInput]['schema:url']];
      } else {
        // control source with '1' and '-1'
        if ( this.currentIndex + direction >= 0
          && this.currentIndex + direction < this.sources.audioObjects.length) {
          this.currentIndex = this.currentIndex+direction
          this.currentSource = this.media[this.sources.audioObjects[this.currentIndex]['schema:url']];
        }
      }
    },

    changeAudioObject(autoplay) {
      // reset audio object
      if (this.audio) {
        this.audio.src = this.currentSource;
      } else {
        this.audio = new Audio(this.currentSource);
      }
      this.playing = false;
      this.currentTimecurrentTime = 0;
      this.checkingCurrentPositionInTrack = null;
      this.audio.loop = false;

      const setDuration = (time) => {
        this.trackDuration = time;
      };

      this.audio.addEventListener('loadedmetadata', function() {
        const duration = this.duration;
        setDuration(duration);
      });

      if (autoplay) {
        this.playAudio();
      }
    },

    timeFormat(s, totalDuration) {
      let seconds = parseInt(s, 10);

      let hours = 0;
      // only add hours if necessary
      if (totalDuration >= 3600) {
        hours = Math.floor(seconds / 3600);
        seconds %= 3600;
      }

      const minutes = Math.floor(seconds / 60);
      seconds %= 60;

      return (
          `${totalDuration >= 3600 ? padStart(hours.toString(), 2, '0') + ':' : ''}` +
          `${padStart(minutes.toString(), 2, '0') + ':'}` +
          `${padStart(seconds.toString(), 2, '0')}`
      );
    },

    playAudio() {
      if (this.audio && !this.playing) {
        this.getCurrentTimeEverySecond();
        this.playing = true;
        this.audio.play();
      } else {
        this.stopAudio();
      }
    },

    stopAudio() {
      if (this.audio) {
        this.audio.pause();
        this.playing = false;
        clearInterval(this.checkingCurrentPositionInTrack);
      }
    },

    getCurrentTimeEverySecond() {
      this.checkingCurrentPositionInTrack = setInterval(() => {
        this.currentTime = this.audio.currentTime;
        this.updateHandlePosition();
      }, 1000);
    },

    updateHandlePosition() {
      if (this.$refs.progressContainer) {
        this.progressHandlePosition = ((this.$refs.progressContainer.offsetWidth *
            (this.currentTime / this.trackDuration))-4).toString() + 'px';
      }
    },

    changeCurrentTime(e) {
      this.audio.pause();
      const width = this.$refs.progressContainer.offsetWidth;
      // get chosen percentage in progressbar and apply it to audio.currentTime
      this.audio.currentTime = this.trackDuration * (e.layerX / width);
      // reset progress and time elements
      clearInterval(this.checkingCurrentPositionInTrack);
      this.currentTime = this.audio.currentTime;
      this.updateHandlePosition();
      this.getCurrentTimeEverySecond();

      if (this.playing) {
        this.audio.play();
      }
    },

    changeLoopSetting() {
      this.loop = !this.loop;
      this.audio.loop = this.loop;
    },

    showVolumeController() {
      this.VolumeControllerActive = true;
      this.VolumeControllerFade = true;
    },

    cancelVolumeControllerTimer() {
      clearTimeout(this.VolumeControllerTimerFade);
      clearTimeout(this.VolumeControllerTimerVisibility);
    },

    reinstateVolumeControllerTimer(e) {
      this.cancelVolumeControllerTimer();

      let delay = 1500;
      if (e.target) {
        delay = 0;
      }

      this.VolumeControllerTimerFade = setTimeout(() => {
        this.VolumeControllerFade = false;
      }, delay + 250);
      this.VolumeControllerTimerVisibility = setTimeout(() => {
        this.VolumeControllerActive = false;
      }, delay + 500);
    },

    dispose() {
      if (this.audio && this.playing) {
        clearTimeout(this.checkingCurrentPositionInTrack);
        this.stopAudio();
      }
    },
  },

  watch: {
    volume: {
      immediate: true,
      handler() {
        // set audio volume
        if (this.audio) {
          this.audio.volume = this.volume;
        }
      },
    },
    currentIndex: {
      handler(newVal, oldVal) {
        this.changeAudioObject(oldVal !== newVal)
      }
    },
    routeCounter: {
      handler(newVal, oldVal) {
        if (newVal > oldVal) {
          this.dispose();
        }
      }
    }
  },

  unmounted() {
    this.dispose();
  },
});
</script>

<style lang="scss">
.audio-player {

  ion-grid, ion-row, ion-col {
    padding: 0;
    padding-inline: unset;

    .expand {
      flex-grow: 1 !important;
      flex-basis: auto !important;
    }
  }

  .top-area {
    margin: 0 -16px 16px;

    .audio-title-wrapper {
      background: var(--ion-color-tour-contrast);
      margin: 0 auto;

      svg {
        width: 180px;
        height: 180px;

        * {
          fill: var(--ion-color-tour);
        }
      }

      .title {
        background: var(--ion-color-tour);
        color: var(--ion-color-tour-contrast);
        font-size: 1.25rem
      }
    }

    .animated .audio-title-wrapper svg {
      animation: icon-pulse 3s linear infinite alternate;
      animation-fill-mode: both;
    }

    .audio-bg-gradient {
      background: linear-gradient(270deg, #abbbb8, #2b302f);
      background-size: 400% 400%;
      padding: 3rem;

      &.animated {
        animation: gradient-pulse 10s linear infinite alternate;
        animation-fill-mode: both;
      }
    }

    @keyframes icon-pulse {
      0%    { transform: scale(1); }
      50%    { transform: scale(1.1); }
      100%  { transform: scale(1.2); }
    }

    @keyframes gradient-pulse {
      0%    { background-position:0% 50% }
      50%   { background-position:100% 50% }
      100%  { background-position:0% 50% }
    }
  }

  .audio-player-controls-wrapper {
    margin: 0 auto;
    min-width: 260px;

    .main-control svg {
      width: 64px;
      height: 64px;

      *:not(.contrast) {
        fill: var(--ion-color-tour);
      }
    }

    svg.disabled {
      opacity: 0.4;
    }

    .play-button-wrapper {
      flex: 0 0 80px;
    }

    .play-button {
      a {
        display: inline-block;
        text-align: center;

        svg {
          width: 80px;
          height: 80px;

          *:not(.contrast) {
            fill: var(--ion-color-tour);
          }
        }
      }
    }

    .progress-container {
      flex: 1 1 auto;
      cursor: pointer;
      position: relative;
      padding-left: 4px;
      top: 4px;
      margin-left: 6px;
      margin-right: 2px;

      .progress-handle {
        position: absolute;
        top: -5px;
        z-index: 1;
        display: block;
        width: 16px;
        height: 16px;
        background: green;
        border-radius: 8px;
        transition: left 0.15s;
        background: var(--ion-color-tour);
      }

      ion-progress-bar {
        border-radius: 3px;
        height: 6px;
      }
    }

    .sub-controls-wrapper {
      margin-top: 1rem;
      padding: 0;
      padding-inline: unset;

      * {
        color: #7b869e;

        svg {
          *:not(.contrast) {
            fill: #7b869e;
          }
        }
      }

      .active svg *:not(.contrast){
        fill: var(--ion-color-tour);
        opacity: 1;
      }

      ion-col {
        flex: 0 0 32px;
      }

      .sub-control svg {
        width: 24px;
        height: 24px;
      }

      .time-container {
        flex: 1 0 50%;
        line-height: 0;
        margin: 0px 2px;
      }
    }

    .external-links {
      padding: 0;
      padding-inline: unset;

      ion-col {
        flex: 0 0 64px;
      }

      .download {
        margin-left: auto;
      }

      svg {
        width: 64px;
        height: 64px;

        *:not(.contrast) {
          fill: var(--ion-color-tour);
        }
      }
    }

    .volume-controller {
      display: flex;
      position: absolute;
      z-index: 1;
      top: 44px;
      left: -9999px;
      width: 50px;
      transform: rotate(-90deg);
      transform-origin: top left;
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.33);
      background: #ffffff;
      padding: 0.75rem 1.25rem 0.75rem 0.75rem;
      border-radius: 2rem;
      opacity: 0;
      transition: opacity 0.2s, width 0.2s;

      &.active {
        left: -15px;
      }

      &.fade-in {
        opacity: 1;
        width: 200px;
      }

      > div {
        width: 2rem;
        height: 1.5rem;
        text-align: center;
        position: relative;
        transform: rotate(90deg);
      }

      input[type=range] {
        -webkit-appearance: none;
        width: 100%;
        background: transparent;

        &::-webkit-slider-thumb {
          -webkit-appearance: none;
          border: none;
          height: 16px;
          width: 16px;
          border-radius: 8px;
          background: var(--ion-color-tour);
          margin-top: -4px;
        }

        &::-webkit-slider-runnable-track {
          width: 100%;
          height: 8px;
          background: rgba(var(--ion-color-tour-rgb), 0.3);
          border-radius: 4px;
          border: none;
        }

        &:focus {
          outline: none;

          &::-webkit-slider-runnable-track {
            background: var(--ion-color-tour);
          }
        }
      }
    }
  }

  .transcript-handler {
    margin-top: 32px;
  }

  .chapter-selection {
    line-height: 1em;

    .chapter-select-toggler {
      cursor: pointer;

      h3 {
        display: flex;
        justify-content: center;
        margin: 0;
        color: var(--ion-color-tour);
        max-height: 22px;

        span {
          display: inline-block;
          line-height: 24px;
        }

        .collapse-toggle {
          margin-left: 0.5em;
        }

        svg {
          color: var(--ion-color-tour);
          top: -4px;
        }
      }
    }

    .chapter-label {
      text-transform: none;
    }

    .chapter-content {
      .chapter-item {
        margin: 8px 0;
        padding: 8px 0;
        border-top: 1px solid var(--ion-color-light-shade);

        &:last-child {
          border-bottom: none;
        }
      }
    }
  }
}

</style>
