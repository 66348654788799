
import {defineComponent} from "vue";
import { IonText } from "@ionic/vue"
import MapDisplay from '@/components/map-display.vue';

export default defineComponent ({
  name: "TourMobilityWidget",

  components: {
    IonText,
    MapDisplay
  },

  props: {
    tourData: {
      type: Object,
      default: null
    },

    widgetContent: {
      type: Object,
      default: null
    }
  },

  data() {
    return {
      places: [],
    }
  },

  watch: {
    'tourData.id': {
      immediate: true,
      handler() {
          const places = [];
          if (this.tourData['epapp:places'] && this.tourData['epapp:places'].length) {
            this.tourData['epapp:places'].forEach((place, index) => {
              if (place['schema:geo']) {
                places.push({
                  'schema:geo': place['schema:geo'],
                  index: index+1
                })
              }
            })
          }
          this.places = places;
        }
      }
    }

});
