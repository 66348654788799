
import {defineComponent} from "vue";
import {IonText} from '@ionic/vue';
import MapDisplay from '@/components/map-display.vue';
import AddressDisplay from "@/components/address-display.vue";

export default defineComponent ({
  name: "LocationWidget",

  components: {
    IonText,
    MapDisplay,
    AddressDisplay,
  },

  props: {
    widgetContent: {
      type: Object,
      default: null
    },
    placeData: {
      type: Object,
      default: null
    },
  },
});
