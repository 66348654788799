<template>
  <div>
    <div class="accessibility-info">
      <div class="acc-level-info ion-text-center" v-if="certificationStatus === 'info'">
        <div class="seal">
          <a href="https://www.reisen-fuer-alle.de/" target="_blank">
            <img :src="imgAInfo">
          </a>
        </div>
      </div>

      <div class="acc-level-certified" v-if="certificationStatus === 'checked'">
        <div class="seal">
          <a href="https://www.reisen-fuer-alle.de/" target="_blank">
            <img :src="imgAVeri">
          </a>
        </div>

        <template v-for="(cert, key) in accessibiltyMap" :key="'cert'+key">
          <div class="cert" v-if="cert.valuePartial !== 'none'" :class="{ 'info-mark': cert.valuePartial === 'info'}">
            <a href="https://www.reisen-fuer-alle.de/" target="_blank">
              <img :src="symbolsMap[cert.label]['partial']">
            </a>
          </div>
          <div class="cert" v-if="cert.valueFull !== 'none'" :class="{ 'info-mark': cert.valueFull === 'info'}">
            <a href="https://www.reisen-fuer-alle.de/" target="_blank">
              <img :src="symbolsMap[cert.label]['full']">
            </a>
          </div>
        </template>
      </div>

    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import imgAInfo from '@/assets/images/accessibility/accessibility_info.png';
import imgAVeri from '@/assets/images/accessibility/accessibility_verified.png';
import blindPartialSymbol from '@/assets/images/accessibility/seal_blind_partial.png';
import blindFullSymbol from '@/assets/images/accessibility/seal_blind_full.png';
import deafPartialSymbol from '@/assets/images/accessibility/seal_deaf_partial.png';
import deafFullSymbol from '@/assets/images/accessibility/seal_deaf_full.png';
import mentalFullSymbol from '@/assets/images/accessibility/seal_mental_full.png';
import walkingPartialSymbol from '@/assets/images/accessibility/seal_walking_partial.png';
import walkingFullSymbol from '@/assets/images/accessibility/seal_walking_full.png';

export default defineComponent({
  name: "AccessibilityInfo",

  props: {
    data: {
      type: Object,
      required: true
    }
  },

  setup(props) {
    const node = {
      ...
          props.data
    }
    return { node, imgAInfo, imgAVeri }
  },

  computed: {
    symbolsMap(): any {
      return {
        'blind': {
          'partial': blindPartialSymbol,
          'full': blindFullSymbol,
        },
        'deaf': {
          'partial': deafPartialSymbol,
          'full': deafFullSymbol,
        },
        'walking': {
          'partial': walkingPartialSymbol,
          'full': walkingFullSymbol,
        },
        'mental': {
          'full': mentalFullSymbol,
        }
      }
    },

    certificationStatus(): string {
      if ( this.node['thuecat:accessibilitySpecification']
           && this.node['thuecat:accessibilitySpecification']['thuecat:accessibilityCertification']) {
        const status = this.node['thuecat:accessibilitySpecification']['thuecat:accessibilityCertification']['thuecat:accessibilityCertificationStatus'];

        switch (status) {
          default:
            return 'none';
          case 'thuecat:CertificationAccessibilityNoCertification':
            return 'none';
          case 'CertificationInformationAboutAccessibility':
            return 'info';
          case 'thuecat:AccessibilityChecked':
            return 'checked';
        }
      }
      return 'none'
    },

    accessibiltyMap(): any {
      const accMap: any[] = [
        {
          label: 'deaf',
          fullProp: 'certificationAccessibilityDeaf',
          partialProp: 'certificationAccessibilityPartiallyDeaf'
        },
        {
          label: 'blind',
          fullProp: 'certificationAccessibilityVisual',
          partialProp: 'certificationAccessibilityPartiallyVisual'
        },
        {
          label: 'walking',
          fullProp: 'certificationAccessibilityWheelchair',
          partialProp: 'certificationAccessibilityWalking'
        },
        {
          label: 'mental',
          fullProp: 'certificationAccessibilityMental',
        }
      ];

      if ( this.node['thuecat:accessibilitySpecification']
          && this.node['thuecat:accessibilitySpecification']['thuecat:accessibilityCertification'] ) {
        return accMap.map(item => {
          {
            const props = {
              ...item
            };

            return {
              label: item.label,
              valueFull: this.certMapping(this.node['thuecat:accessibilitySpecification']['thuecat:accessibilityCertification']['thuecat:'+props.fullProp]),
              valuePartial: this.certMapping(this.node['thuecat:accessibilitySpecification']['thuecat:accessibilityCertification']['thuecat:'+props.partialProp]),
            }
          }
        });
      }

      return {}
    }
  },

  methods: {
    certMapping(value: string) {
      switch(value) {
        default:
          return 'none';
        case 'thuecat:Full':
          return 'full';
        case 'thuecat:Info':
          return 'info'
      }
    }
  }

});
</script>

<style lang="scss" scoped>
$seal-width: 225px;
$cert-width: 80px;
$cert-margin: 4px;

.accessibility-info {
  margin: 0 auto;

  .acc-level-info {
    img {
      width: $seal-width;
    }
  }

  .acc-level-certified {
    position: relative;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    .seal {
      margin: 0 $cert-margin $cert-margin;
      flex-basis: 100%;
      text-align: center;

      img {
        width: $seal-width;
      }
    }

    .cert {
      margin: 0 $cert-margin $cert-margin;
      position: relative;
      flex: 0 0 80px;

      img {
        width: $cert-width;
      }

      &.info-mark {
        &::after {
          position: absolute;
          width: 20px;
          height: 20px;
          background: white;
          text-align: center;
          content:'i';
          z-index: 1;
          right: 0px;
          font-size: 16px;
          line-height: 24px;
          color: #2e4047;
          font-weight: bold;
          border: 1px solid #9a9a99;
          border-radius: 2px;
        }
      }
    }
  }
}
</style>
