<template>
  <svg version="1.1"
       xmlns="http://www.w3.org/2000/svg"
       x="0px" y="0px" viewBox="0 0 165.49 167.42"
       style="enable-background:new 0 0 165.49 167.42;" xml:space="preserve">
    <circle cx="84" cy="84" r="50" fill="#ffffff" class="contrast" />
			<path d="M119.71,109.47c-0.22,0-0.41,0-0.61,0c-10.42-0.01-20.84-0.01-31.26-0.02c-10.16,0-20.32,0-30.49,0
				c-0.32,0-0.5,0.03-0.5,0.43c0.01,2.78,0.01,5.55,0,8.33c0,0.31,0.08,0.41,0.41,0.41c20.64-0.01,41.29-0.01,61.92-0.01h0.51
				V109.47z M98.29,76.05v-0.69c0-11.27,0-22.54,0-33.8c0-0.61,0-0.61-0.62-0.61c-7.34,0-14.67,0-22.02,0c-0.65,0-0.65,0-0.65,0.68
				c0,11.27,0,22.53,0,33.8c0,0.59,0,0.59-0.59,0.59c-3.76,0-7.5,0-11.26,0h-0.67c8.1,10.72,16.1,21.3,24.15,31.94
				c8.06-10.66,16.06-21.24,24.12-31.91H98.29z M86.99,25.39h1.82c0.18,0.03,0.35,0.09,0.53,0.1c1.2,0.07,2.4,0.08,3.58,0.22
				c2.08,0.24,4.16,0.45,6.2,0.85c7.5,1.44,14.43,4.3,20.8,8.5c4.38,2.89,8.29,6.29,11.74,10.23c2.18,2.49,4.14,5.15,5.89,7.97
				c2.76,4.47,4.86,9.23,6.33,14.26c0.82,2.83,1.44,5.69,1.78,8.62c0.15,1.35,0.28,2.7,0.37,4.06c0.09,1.41,0.17,2.82,0.16,4.23
				c-0.02,1.56-0.12,3.12-0.24,4.68c-0.22,2.93-0.73,5.83-1.45,8.68c-2.72,10.81-8.1,20.1-16.1,27.87
				c-4.63,4.49-9.87,8.09-15.71,10.83c-7.37,3.47-15.15,5.26-23.28,5.51c-4.29,0.13-8.54-0.29-12.75-1.12
				c-7.41-1.47-14.28-4.28-20.6-8.41c-4.11-2.68-7.82-5.85-11.13-9.48c-2.29-2.51-4.34-5.18-6.17-8.04
				c-2.12-3.3-3.88-6.8-5.28-10.46c-2.32-6.08-3.59-12.38-3.78-18.88c-0.01-0.33-0.08-0.66-0.12-0.99v-1.82
				c0.03-0.16,0.09-0.32,0.1-0.48c0.14-1.82,0.19-3.66,0.42-5.47c0.26-2.07,0.6-4.13,1.04-6.17c0.78-3.6,1.97-7.06,3.45-10.43
				c1.14-2.57,2.46-5.05,3.94-7.43c1.67-2.67,3.55-5.18,5.63-7.54c2.12-2.4,4.4-4.62,6.87-6.64c4.83-3.95,10.15-7.06,15.98-9.3
				c6.21-2.39,12.64-3.67,19.29-3.84C86.52,25.49,86.75,25.43,86.99,25.39" fill="#000000"/>
  </svg>
</template>

