<template>
  <div class="address-container ion-padding" v-if="showLocationInformation">

    <div class="ion-margin-bottom ion-text-center">
      <h2>
        {{ title || node['schema:name'] }}
      </h2>

      <div v-if="mainAddress" class="address-text">
        <div v-if="mainAddress['schema:streetAddress']">
          {{ mainAddress['schema:streetAddress'] }}
        </div>
        <div>
          {{ mainAddress['schema:postalCode'] }}
          {{ mainAddress['schema:addressLocality'] }}
        </div>
        <div v-if="mainAddress['schema:telephone']">
          Tel. <a :href="`tel:${parsePhoneGlobal(mainAddress['schema:telephone'])}`"
                  class="text-white">{{ mainAddress['schema:telephone'] }}</a>
        </div>
      </div>

    </div>

    <div class="ion-text-center external-links ion-padding-vertical">

      <div v-if="mainAddress"
           @click="goToHafas()"
           class="external-link-icon"
           :aria-label="$t('components.locationInformation.travelByTrain')"
           :title="$t('components.locationInformation.travelByTrain')"
           role="link">
        <PublicTransportIcon/>
        <form ref="publicTransportForm"
              action="https://vmt.hafas.de/bin/query.exe"
              target="_blank"
              rel="noopener noreferrer">
          <input type="hidden" name="Z" :value="hafasDestination"/>
        </form>
      </div>

      <div v-if="
        (mainAddress &&
          (mainAddress['schema:name'] || mainAddress['schema:streetAddress'])) ||
          (node['schema:geo'] &&
            node['schema:geo']['schema:latitude'] &&
            node['schema:geo']['schema:longitude']) "
           @click="goToGmap(mainAddress, node['schema:geo'])"
           class="external-link-icon"
           :aria-label="$t('components.locationInformation.travelByCar')"
           :title="$t('components.locationInformation.travelByCar')"
           role="link">
        <IndividualTransportIcon/>
      </div>

      <div v-if="node['schema:hasMap']"
           @click="openUrl(node['schema:hasMap'])"
           class="external-link-icon"
           role="link"
           :aria-label="$t('components.locationInformation.map')"
           :title="$t('components.locationInformation.map')">
        <LocationIcon/>
      </div>

    </div>

    <div class="website-link ion-text-center ion-margin-bottom ion-padding-top" v-if="node['schema:url']">
      <ion-button @click="openUrl(node['schema:url'])"
                  color="primary"
                  class="large"
                  expand="block"
                  shape="round"
                  role="link">
        {{ $t('components.locationInformation.visitWebsite') }}
      </ion-button>
    </div>

  </div>
</template>

<script>
import { defineComponent } from 'vue';
import LocationIcon from '@/assets/svg/location.vue';
import IndividualTransportIcon from '@/assets/svg/car.vue';
import PublicTransportIcon from '@/assets/svg/train.vue';
import { IonButton } from '@ionic/vue';

export default defineComponent({
  name: 'address-display',

  components: {
    LocationIcon,
    IndividualTransportIcon,
    PublicTransportIcon,
    IonButton,
  },

  props: {
    primaryAddressType: {
      type: String,
      default: 'thuecat:HouseAddress',
    },
    secondaryAddressTypes: {
      type: String,
      default: '',
    },
    data: {
      type: Object,
      required: true
    },
    title: {
      type: String,
      default: '',
    }
  },

  setup(props) {
    const node = {
      ...
          props.data
    }
    return { node }
  },

  data() {
    return {
      primaryAddressTypeValue: this.primaryAddressType.trim() || 'thuecat:HouseAddress',
      secondaryAddressTypeValues: this.secondaryAddressTypes.split(',').map((v) => v.trim()),
    };
  },

  computed: {
    mainAddress() {
      if (this.node['schema:address']) {
        const addrCandidates = Array.isArray(this.node['schema:address'])
                               ? this.node['schema:address']
                               : [this.node['schema:address']];

        const mainAddress = this.getAddressByType(addrCandidates);

        if (mainAddress) {
          return mainAddress;
        }
      }
      return false;
    },

    hafasDestination() {
      // will create an address string out of the address schema to provide as hafas direction
      const addressArr = this.convertAddressString(this.mainAddress);
      let hafasDestination = '';
      addressArr.forEach((addPart) => {
        hafasDestination += addPart + ' ';
      });
      return hafasDestination.substr(0, hafasDestination.length - 1);
    },
    showLocationInformation() {
      return (
          !!this.node &&
          !!(
              this.node['schema:name'] ||
              this.mainAddress ||
              this.node['schema:geo'] ||
              this.node['schema:hasMap'] ||
              this.node['schema:url']
          )
      );
    },
  },

  methods: {
    convertAddressString(address) {
      return [
        address['schema:streetAddress'],
        address['schema:postalCode'],
        address['schema:addressLocality'],
      ];
    },
    openUrl(url) {
      window.open(url, '_blank');
    },
    getAddressByType(addrCandidates) {
      const addressTypePriorityList = [
        this.primaryAddressTypeValue,
        ...this.secondaryAddressTypeValues,
      ];

      let mainAddress;
      // we check for 'typOfAddress' and 'typeOfAddress' since the DS was changed
      for (let iter = 0; iter < addressTypePriorityList.length; iter++) {
        mainAddress = addrCandidates.find(
            (addr) =>
                (addr['thuecat:typOfAddress'] &&
                 addr['thuecat:typOfAddress'] ===
                 addressTypePriorityList[iter]) ||
                (addr['thuecat:typeOfAddress'] &&
                 addr['thuecat:typeOfAddress'] ===
                 addressTypePriorityList[iter])
        );
        if (mainAddress) {
          break;
        }
        // fallback: if no types are set or found (ex. Destinations) ...
        if (addrCandidates.length) {
          // ... return the first address from the collection
          mainAddress = {
            ...addrCandidates[0],
          };
        }
      }

      if (mainAddress.label) {
        this.addressLabel = mainAddress.label;
      }

      return mainAddress;
    },
    goToHafas() {
      // submit the hidden form inside the hafas button
      this.$refs.publicTransportForm.submit();
    },
    goToGmap(address, geo) {
      if (address) {
        const addressArr = this.convertAddressString(address);
        addressArr.push(address['schema:name']);
        let mapsUrl = 'https://www.google.de/maps/dir//';
        addressArr.forEach((addPart) => {
          mapsUrl += addPart + '+';
        });
        window.open(mapsUrl.substr(0, mapsUrl.length - 1), '_blank');
        return;
      }
      window.open(
          `https://www.google.de/maps/dir//` +
          `${geo['schema:latitude']}, ${geo['schema:longitude']}?hl=de`,
          '_blank'
      );
    },
    parsePhoneGlobal(phoneNum) {
      if (!phoneNum || typeof phoneNum !== 'string') {
        return phoneNum;
      }
      return phoneNum.replace('(0)', '').replace(/\s/g, '');
    },
  },
})

</script>

<style lang="scss" scoped>
.address-container {
  * {
    color: var(--ion-color-tour-contrast);
  }

  h2 {
    margin-top: 0;
  }

  background: var(--ion-color-tour);

  .address-text {
    line-height: 140%;
  }

  .external-links {
    display: flex;
    justify-content: center;

    ::v-deep(.external-link-icon) {
      margin: 0 8px;
      background: white;
      border-radius: 30px;
      flex: 0 0 60px;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        width: 48px;
        max-height: 32px;

        path {
          stroke: var(--ion-color-tour);
        }
      }
    }
  }

  .website-link {
    ion-button::part(native) {
      background: var(--ion-color-tour-contrast);
      color: var(--ion-color-tour);
      text-transform: uppercase;
    }
  }
}
</style>
