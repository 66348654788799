
import {defineComponent} from "vue";
import {mapState} from "vuex";
import {AppState} from "@/store-management/vuex";

import TourHeaderWidget from '@/widgets/tour/tour-header-widget.vue';
import TourOfflineModeWidget from '@/widgets/tour/tour-offline-mode-widget.vue';
import TourPoiListWidget from '@/widgets/tour/tour-poi-list-widget.vue';
import TourMobilityWidget from '@/widgets/tour/tour-mobility-widget.vue';
import AudioWidget from '@/widgets/common/audio-widget.vue';
import VideoWidget from '@/widgets/common/video-widget.vue';
import TextWidget from '@/widgets/common/text-widget.vue';
import OpeningHoursWidget from '@/widgets/common/opening-hours-widget.vue';
import LocationWidget from '@/widgets/common/location-widget.vue';
import InfoWidget from '@/widgets/common/info-widget.vue';
import ContentSliderWidget from '@/widgets/common/content-slider-widget.vue';
import AccessibilityWidget from '@/widgets/common/accessibility-widget.vue';

export default defineComponent ({
  name: "WidgetSelector",

  components: {
    TourHeaderWidget,
    TourOfflineModeWidget,
    TourPoiListWidget,
    TourMobilityWidget,
    AudioWidget,
    VideoWidget,
    TextWidget,
    InfoWidget,
    AccessibilityWidget,
    OpeningHoursWidget,
    LocationWidget,
    ContentSliderWidget
  },

  props: {
    type: {
      type: String,
      required: true
    },
    widgetContent: {
      type: Object,
      default: null
    }
  },

  setup() {
    const typesWidgetMap = {
      'epapp:IntroductionFeature': 'tour-header-widget',
      'epapp:TourPlacesList': 'tour-poi-list-widget',
      'epapp:TourPlacesMap': 'tour-mobility-widget',
      'epapp:TourOfflineMode': 'tour-offline-mode-widget',

      'epapp:AudioFeature': 'audio-widget',
      'epapp:VideoFeature': 'video-widget',
      'epapp:TextFeature': 'text-widget',
      'epapp:ImageFeature': 'content-slider-widget',

      'epapp:OpeningHoursFeature': 'opening-hours-widget',
      'epapp:LocationFeature': 'location-widget',
      'epapp:InfoFeature': 'info-widget',
      'epapp:AccessibilityFeature': 'accessibility-widget',
    }

    return {typesWidgetMap}
  },

  computed: {
    tourId(): string {
      return this.$route.params.tour?.toString();
    },
    placeId(): string {
      return this.$route.params.place?.toString();
    },
    // get current Vuex states
    ...mapState({
      tourData(state: AppState) {
        return state.tours[this.tourId] || null;
      },
      regionData(state: AppState) {
        return state.region || null;
      },
      media: state => state.media
    }),
    placeData() {
      if (this.tourData && this.tourData["epapp:places"] && this.tourData["epapp:places"].length) {
        return this.tourData["epapp:places"].filter((place: any) => (place['@id'] === this.placeId))[0] || null
      }
      return null
    },
  },
});
