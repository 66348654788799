<template>
  <svg version="1.1"
       xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 165.49 167.42"
       style="enable-background:new 0 0 165.49 167.42;" xml:space="preserve">
        <circle cx="84" cy="84" r="50" fill="#ffffff" class="contrast" />
        <path d="M72.63,75.19c0,3.98,3.36,7.16,7.48,7.11c4.13-0.06,7.35-3.19,7.35-7.13c0-3.98-3.2-7.05-7.36-7.07
          C75.89,68.08,72.63,71.17,72.63,75.19 M70.79,83.33c-0.27-0.34-0.52-0.66-0.78-0.99c-2.22-2.86-3.11-6.06-2.27-9.62
          c1.99-8.42,12.26-12.15,19.63-7.17c3.36,2.27,5.15,5.49,5.28,9.48c0.09,2.8-0.88,5.4-2.78,7.52c-0.69,0.77-0.5,1.1,0.24,1.66
          c0.87,0.66,1.68,1.44,2.36,2.29c0.61,0.76,0.99,0.71,1.57,0.04c3.89-4.41,5.31-9.53,3.96-15.24C95.77,61.82,86,56.45,76.75,58.05
          c-9.13,1.58-15.09,9.16-15.14,16.64c-0.03,4.69,1.5,8.69,4.68,12.09c0.15,0.16,0.35,0.25,0.5,0.35
          C68.12,85.86,69.4,84.65,70.79,83.33 M69.89,96.34c0.18,3.53,1.36,8,3.09,12.3c0.93,2.3,2.09,4.57,3.51,6.6
          c2.15,3.07,4.85,2.98,7.16,0c0.74-0.95,1.39-2,1.94-3.08c2.4-4.79,3.85-9.9,4.55-15.19c0.88-6.64-3.13-10.14-7.3-11.29
          C76.2,83.88,69.73,88.63,69.89,96.34 M95.2,98.79c0.46-0.22,0.71-0.3,0.92-0.44c10.06-6.22,15.29-17.47,12.6-29.14
          c-2.89-12.55-14.32-21.24-27.66-21.74c-4.62-0.17-9.05,0.73-13.32,2.53c-11.17,4.7-18.13,15.84-16.95,27.82
          c0.88,8.88,5.52,15.6,12.98,20.42c0.27,0.18,0.59,0.27,1,0.44c0.03-0.42,0.08-0.72,0.08-1.01c0.01-1.59,0.07-3.17-0.03-4.75
          c-0.03-0.48-0.37-1.06-0.74-1.39c-7.59-6.68-9.75-16.95-5.39-25.76c4.02-8.1,13.05-13.31,22.71-12.72
          c5.51,0.34,10.4,2.16,14.53,5.79c5.86,5.15,8.5,11.66,7.43,19.44c-0.74,5.32-3.37,9.7-7.39,13.26c-0.32,0.28-0.72,0.69-0.73,1.04
          C95.17,94.59,95.2,96.59,95.2,98.79 M80.73,142.01h-1.37c-0.71-0.1-1.4-0.23-2.12-0.31c-2.48-0.26-5-0.33-7.45-0.78
          c-13.34-2.49-24.6-8.76-33.54-19c-8.91-10.23-13.75-22.14-14.32-35.74c-0.03-0.56-0.2-1.11-0.29-1.67V82.7
          c0.1-0.67,0.24-1.33,0.31-2c0.26-2.45,0.33-4.93,0.78-7.34c2.49-13.34,8.73-24.64,19.01-33.53
          c14.02-12.11,30.34-16.56,48.54-13.5c13.38,2.25,24.65,8.69,33.54,18.98c12.11,14.01,16.58,30.34,13.51,48.53
          c-2.24,13.34-8.68,24.55-18.88,33.48c-10.31,9.02-22.33,13.89-36.06,14.42C81.83,141.75,81.28,141.92,80.73,142.01" fill="#000000"/>
  </svg>
</template>

