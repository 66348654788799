
import {defineComponent} from "vue";
import VisitorInfo from '@/components/visitor-info.vue';

export default defineComponent ({
  name: "InfoWidget",

  components: {
    VisitorInfo
  },

  props: {
    widgetContent: {
      type: Object,
      default: null
    },
  },

  setup() {
    const properties: any[] =  [
      'thuecat:photography',
      'thuecat:gastro',
      'thuecat:sanitation',
      'thuecat:otherService',
      'thuecat:digitalOffer',
      'thuecat:guidedTour',
      'thuecat:entrance',
    ];

    return { properties }
  },

  computed: {
    availableProps(): any[] {
      return this.properties.filter((prop) => this.widgetContent[prop]);
    }
  }
});
