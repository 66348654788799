
import {defineComponent} from "vue";
import OfflineMode from '@/components/offline-mode.vue';

export default defineComponent ({
  name: "TourOfflineModeWidget",

  components: {
    OfflineMode
  },

  props: {
    regionData: {
      type: Object,
      default: null
    },

    tourData: {
      type: Object,
      default: null
    }
  }
});
