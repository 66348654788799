<template>
  <div class="oh-wrapper" v-if="openingHoursDataAvailable" :class="{ 'ion-padding-bottom': !collapsed }">
    <div>
      <ion-grid>
        <ion-row>
          <ion-col>
            <h2 class="">{{ $t('components.openingHours.title') }}</h2>
          </ion-col>
        </ion-row>

        <ion-row class="openinghours-header">
          <ion-col size="6">
            <div :class="`is-${isOpened ? 'opened' : 'closed'}`">
              {{ $t(`components.openingHours.${currentDay && isOpened ? 'currentlyOpened' : 'currentlyClosed'}`) }}
            </div>
          </ion-col>
          <ion-col v-if="isOpened" size="6" :style="{ paddingRight: '2em' }">
            <div v-for="timespan in (currentDay || []).timespans" :key="timespan">
              {{ timespan }} {{ $t(`components.openingHours.time`) }}
            </div>
          </ion-col>
          <ion-col @click="switchCollapse()"
                   :class="['collapse-toggle ion-text-end', { active: !collapsed }]"
                   role="button"
                   :aria-label="$t('components.openingHours.showDetails')">
            <ChevronDown></ChevronDown>
          </ion-col>
        </ion-row>
      </ion-grid>

      <div class="transition-anchor-slide">
        <transition-group name="slidedown">
          <div v-if="!collapsed" key="transition-oh">
            <ion-grid v-for="(range, rangeindex) in defaultGroupedOpeningHours"
                      :key="rangeindex">

              <template v-if="range.validRange.from">
                <ion-row v-if=" (rangeindex && rangeindex === 1)
                                || (!rangeindex && dayIsBefore(range.validRange.from)) ">
                  <ion-col>
                    <h3>
                      {{ $t('components.openingHours.futureTitle') }}
                    </h3>
                  </ion-col>
                </ion-row>

                <ion-row
                    v-for="(rangeGroup, index) in range.items"
                    :key="rangeindex + '_' + index"
                    :class="[
                      'date-rangeset',
                      getDayOfWeekClasses(rangeGroup),
                      {
                        'is-active-time':
                          getDayOfWeekClasses(rangeGroup).includes('day-today') &&
                          isValidRange(range.validRange) &&
                          (currentDay && currentDay.source === 'default'),
                      },
                  ]">
                  <ion-col size="7" class="oh-label">
                    <div>
                      {{ generateNames(rangeGroup) }}
                    </div>
                  </ion-col>
                  <ion-col size="auto" offset="1" class="ion-padding-start oh-time">
                    <div v-if="getFirstGroupItemProp(rangeGroup, 'closed')">
                      {{ $t('components.openingHours.closed') }}
                    </div>
                    <div v-else-if="!(getFirstGroupItemProp(rangeGroup, 'timespans') || []).length">
                      {{ $t('components.openingHours.opened') }}
                    </div>
                    <div v-else
                         v-for="timespan in getFirstGroupItemProp(rangeGroup, 'timespans') || []"
                         :key="timespan">
                      {{ timespan }}
                    </div>
                  </ion-col>
                </ion-row>

                <ion-row class="valid-description">
                  <ion-col>
                    <small>
                      <span v-if="range.validRange.from">
                        {{ $t('components.openingHours.validFrom') }}
                        {{ parseDate(range.validRange.from) }}
                      </span>
                      <span v-if="range.validRange.through">
                        <br/>{{ $t('components.openingHours.validThrough') }}
                        {{ parseDate(range.validRange.through) }}
                      </span>
                    </small>
                  </ion-col>
                </ion-row>
              </template>
            </ion-grid>

            <template v-if="specialGroupedOpeningHours.length">
              <h3>
                {{ $t('components.openingHours.specialTitle') }}
              </h3>

              <ion-grid
                  v-for="(specialrange, rangeindex) in specialGroupedOpeningHours"
                  :key="rangeindex + '_special'">
                <ion-row
                    v-for="(rangeGroup, index) in specialrange.items"
                    :key="rangeindex + '_' + index + '_special'"
                    class="row date-rangeset"
                    :class="[
                    { 'border-bottom': !getFirstGroupItemProp(rangeGroup, 'closed') },
                    getDayOfWeekClasses(rangeGroup),
                    {
                      'font-weight-bold':
                        getDayOfWeekClasses(rangeGroup).includes('day-today') &&
                        isValidRange(specialrange.validRange) &&
                        (currentDay && currentDay.source === 'special'),
                    },
                  ]"
                >
                  <template v-if="!getFirstGroupItemProp(rangeGroup, 'closed')">
                    <ion-col class="oh-label" size="7">
                      {{ generateNames(rangeGroup) }}
                    </ion-col>
                    <ion-col class="oh-time" size="auto" offset="1">
                      <template v-if="!(getFirstGroupItemProp(rangeGroup, 'timespans') || []).length">
                        {{ $t('components.openingHours.opened') }}
                      </template>
                      <template v-else
                                v-for="timespan in getFirstGroupItemProp(rangeGroup, 'timespans') || []"
                                :key="timespan"
                      >
                        {{ timespan }}
                      </template>
                    </ion-col>
                  </template>
                </ion-row>

                <ion-row class="valid-description">
                  <ion-col class="text-muted">
                    <small>
                      <span v-if="specialrange.validRange.from">
                        {{ $t('components.openingHours.validFrom') }}
                        {{ parseDate(specialrange.validRange.from) }}
                      </span>
                      <span v-if="specialrange.validRange.through">
                        <br/>{{ $t('components.openingHours.validThrough') }}
                        {{ parseDate(specialrange.validRange.through) }}
                      </span>
                    </small>
                  </ion-col>
                </ion-row>
              </ion-grid>
            </template>
          </div>
        </transition-group>
      </div>

    </div>
  </div>
</template>

<script>
import {defineComponent} from 'vue';
import dayjs from 'dayjs';
import 'dayjs/locale/de';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import {groupBy, orderBy, flatten, zip, padStart, partition, sortBy, compact} from 'lodash-es';
import {IonGrid, IonRow, IonCol} from '@ionic/vue';

import ChevronDown from "@/assets/svg/chevron-down";

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(localizedFormat);

export default defineComponent({
  components: {
    IonGrid,
    IonRow,
    IonCol,
    ChevronDown,
  },

  props: {
    data: {
      type: Object,
      required: true
    }
  },

  setup(props) {
    const node = {
      ...
          props.data
    };

    return {node}
  },

  created() {

    setInterval(() => {
      this.now = Date.now();
      this.currentDay = this.getOpeningHoursForDay(this.daysOfWeek[dayjs().day()].name);
    }, 1000);
    this.currentDay = this.getOpeningHoursForDay(this.daysOfWeek[dayjs().day()].name);

    dayjs.locale(this.$i18n.locale.substr(0, 2));
  },

  data() {
    return {
      offset: new Date().getTimezoneOffset() / 60,
      now: Date.now(),
      collapsed: true,
      currentDay: {},
      dayOfWeekMap: {
        'http://schema.org/Monday': this.$t('components.openingHours.dayOfWeek.monday'),
        'http://schema.org/Tuesday': this.$t('components.openingHours.dayOfWeek.tuesday'),
        'http://schema.org/Wednesday': this.$t('components.openingHours.dayOfWeek.wednesday'),
        'http://schema.org/Thursday': this.$t('components.openingHours.dayOfWeek.thursday'),
        'http://schema.org/Friday': this.$t('components.openingHours.dayOfWeek.friday'),
        'http://schema.org/Saturday': this.$t('components.openingHours.dayOfWeek.saturday'),
        'http://schema.org/Sunday': this.$t('components.openingHours.dayOfWeek.sunday'),
        'http://schema.org/PublicHolidays': this.$t(
            'components.openingHours.dayOfWeek.publicHolidays'
        ),
      },
      daysOfWeek: [
        this.$t('components.openingHours.dayOfWeek.sunday'),
        this.$t('components.openingHours.dayOfWeek.monday'),
        this.$t('components.openingHours.dayOfWeek.tuesday'),
        this.$t('components.openingHours.dayOfWeek.wednesday'),
        this.$t('components.openingHours.dayOfWeek.thursday'),
        this.$t('components.openingHours.dayOfWeek.friday'),
        this.$t('components.openingHours.dayOfWeek.saturday'),
      ].map((dow) => ({name: dow, short: dow})),

      openingHoursDataAvailable: () => {
        if (!this.node['schema:openingHoursSpecification']) {
          return false;
        }
        const ohsa = Array.isArray(this.node['schema:openingHoursSpecification'])
            ? this.node['schema:openingHoursSpecification']
            : [this.node['schema:openingHoursSpecification']];
        return (
            !!ohsa.filter((ohs) => ohs['schema:dayOfWeek']).length &&
            !!ohsa.filter(
                (ohs) =>
                    !ohs['schema:validThrough'] ||
                    (ohs['schema:validThrough']['@value'] &&
                        this.dayIsBefore(ohs['schema:validThrough']['@value']))
            ).length
        );
      },
    };
  },
  computed: {

    isOpened() {
      if (!this.currentDay || this.currentDay.closed) {
        return false;
      }

      if (!this.currentDay.opens.length && !this.currentDay.closes.length) {
        return true;
      }

      const todayOpens = this.currentDay.opens.map((opens) => {
        const date = new Date();
        date.setHours(opens.hour || 24);
        date.setMinutes(opens.minute || 0);
        date.setSeconds(0);
        date.setMilliseconds(0);
        return date.getTime();
      });

      const todayCloses = this.currentDay.closes.map((closes) => {
        const date = new Date();
        date.setHours(closes.hour || 24);
        date.setMinutes(closes.minute || 0);
        date.setSeconds(0);
        date.setMilliseconds(0);
        return date.getTime();
      });
      const opened = !!~todayOpens
          .map((opens, index) => opens < this.now && todayCloses[index] > this.now)
          .indexOf(true);

      return !!this.currentDay.closed || opened;
    },

    openingHours() {
      return this.getOpeningHours();
    },

    specialOpeningHours() {
      return this.getOpeningHours('special');
    },

    defaultGroupedOpeningHours() {
      return this.getGroupedOpeningHours(this.defaultFilteredOpeningHours);
    },

    specialGroupedOpeningHours() {
      if (this.specialFilteredOpeningHours.length) {
        return this.getGroupedOpeningHours(this.specialFilteredOpeningHours).filter((range) =>
            this.dayIsBefore(range.validRange.through)
        );
      }
      return [];
    },

    defaultFilteredOpeningHours() {
      return this.partitionOpeningHours(this.openingHours);
    },

    specialFilteredOpeningHours() {
      if (this.specialOpeningHours.length) {
        return this.partitionOpeningHours(this.specialOpeningHours);
      }
      return [];
    },
  },

  methods: {
    switchCollapse() {
      this.collapsed = !this.collapsed;
    },

    getValidRange(item) {
      return {
        from: item.validFrom || '',
        through: item.validThrough || '',
      };
    },

    getOpeningHours(source = 'default') {
      const dataSource = this.node[
          source === 'special'
              ? 'schema:specialOpeningHoursSpecification'
              : 'schema:openingHoursSpecification'
          ];

      if (!dataSource) {
        return [];
      }

      return flatten(
          (Array.isArray(dataSource) ? dataSource : [dataSource]).map((node) => {
            if (Array.isArray(node['schema:dayOfWeek'])) {
              return node['schema:dayOfWeek'].map((day) =>
                  this.parseOpeningTime({...node, ...{'schema:dayOfWeek': day}})
              );
            }

            return this.parseOpeningTime(node);
          })
      );
    },

    getGroupedOpeningHours(data) {
      return data.map((group) => {
        return {
          items: this.groupOpeningHours(group),
          // assuming that all items in a group hold the same valid range we just take one item and extract the data
          validRange: this.getValidRange(group.find((v) => v.validFrom) || {}),
        };
      });
    },

    partitionOpeningHours(data) {
      const partitionedOpeningHours = partition(
          data,
          (oh) =>
              !oh.opens ||
              !oh.closes ||
              ((!oh.validFrom || dayjs().isSameOrAfter(oh.validFrom, 'day')) &&
                  (!oh.validThrough || dayjs().isSameOrBefore(oh.validThrough, 'day')))
      );

      // get current valid Opening hours
      const validOpeningHours = partitionedOpeningHours[0]
          ? this.filterOpeningHours(partitionedOpeningHours[0])
          : [];

      // get all additional opening hour sets, that are not valid at the current time
      const otherOpeningHours = partitionedOpeningHours[1]
          ? groupBy(
              // do not consider opening hour sets that are in the past
              partitionedOpeningHours[1].filter(
                  (oh) => !oh.validThrough || dayjs().isSameOrBefore(oh.validThrough, 'day')
              ),
              'validFrom'
          )
          : [];

      // sort other sets by valid from then send each set to filter method
      const openingHours = (
          Object.values(sortBy(Object.keys(otherOpeningHours), (key) => new Date(key).getTime())).map(
              (key) => otherOpeningHours[key]
          ) || []
      ).map((group) => this.filterOpeningHours(group));

      // put valid opening Hours at front
      if (validOpeningHours.length) {
        openingHours.unshift(validOpeningHours);
      }

      return openingHours;
    },

    groupOpeningHours(openingHours) {
      let opensCloses = groupBy(openingHours, 'timespans');
      opensCloses = Object.keys(opensCloses)
          .filter((key) => key !== 'undefined')
          .map((key) =>
              opensCloses[key].map((day) => {
                day.index = this.daysOfWeek.findIndex(
                    (d) => d.name.toLowerCase() === day.dayOfWeek.toLowerCase()
                );
                day.short =
                    (
                        this.daysOfWeek.find(
                            (item) => item.name.toLowerCase() === day.dayOfWeek.toLowerCase()
                        ) || {}
                    ).short || day.dayOfWeek;
                return day;
              })
          );
      opensCloses = opensCloses.map((group) => this.groupByIndex(group));

      let closed = groupBy(openingHours, 'closed');
      closed = Object.keys(closed)
          .filter((key) => key !== 'false')
          .map((key) =>
              closed[key].map((day) => {
                day.index = this.daysOfWeek.findIndex(
                    (d) => d.name.toLowerCase() === day.dayOfWeek.toLowerCase()
                );
                day.short =
                    (
                        this.daysOfWeek.find(
                            (item) => item.name.toLowerCase() === day.dayOfWeek.toLowerCase()
                        ) || {}
                    ).short || day.dayOfWeek;
                return day;
              })
          );
      closed = closed.map((group) => this.groupByIndex(group));
      return [].concat(opensCloses).concat(closed);
    },

    filterOpeningHours(filtered) {
      const grouped = groupBy(filtered, 'dayOfWeek');
      const compacted = flatten(
          Object.keys(grouped).map((groupKey) => {
            const group = grouped[groupKey];
            const ordered = orderBy(group, ['opens.hour', 'opens.minute'], ['asc', 'asc']);
            const resultOH = ordered.shift();
            ['opens', 'closes'].forEach((prop) => {
              resultOH[prop] = resultOH[prop] ? [resultOH[prop]] : [];
            });
            ordered.forEach((oh) => {
              ['opens', 'closes'].forEach((prop) => {
                if (!oh[prop]) {
                  return;
                }

                resultOH[prop].push(oh[prop]);
              });
            });
            return resultOH;
          })
      );
      const pad = (n) => padStart(n, 2, '0');
      const groupedByTime = groupBy(compacted, (oh) =>
          zip(
              (oh.opens || []).map((open) => `${pad(open.hour)}:${pad(open.minute)}`),
              (oh.closes || []).map((open) => `${pad(open.hour)}:${pad(open.minute)}`)
          )
              .map((zipped) => zipped.join(' - '))
              .join(';#;')
      );
      const groupedByWeekDay = groupBy(
          flatten(
              Object.keys(groupedByTime).map((key) =>
                  groupedByTime[key].map((arr) => ({
                    ...arr,
                    ...{timespans: key.split(';#;').filter((x) => !!x), closed: false},
                  }))
              )
          ),
          'dayOfWeek'
      );
      return Object.values(this.dayOfWeekMap).map(
          (day) =>
              (groupedByWeekDay[day] && groupedByWeekDay[day][0]) || {dayOfWeek: day, closed: true}
      );
    },

    parseOpeningTime(node) {
      if (!node['schema:dayOfWeek']) {
        const [, dayOfWeek] = node.split(':');
        return {
          dayOfWeek: this.dayOfWeekMap[`http://schema.org/${dayOfWeek}`] || dayOfWeek,
        };
      }

      const [, dayOfWeek] = node['schema:dayOfWeek'].split(':');
      const validFrom = node['schema:validFrom'];
      const validThrough = node['schema:validThrough'];
      const opens = node['schema:opens'];
      const closes = node['schema:closes'];

      if (!opens || !closes) {
        return {
          dayOfWeek: this.dayOfWeekMap[`http://schema.org/${dayOfWeek}`] || dayOfWeek,
        };
      }

      const opensParts = opens.split(':');
      let opensHours = Number.parseInt(opensParts[0], 10);
      let opensMinutes = Number.parseInt(opensParts[1], 10);
      const closesParts = closes.split(':');
      let closesHours = Number.parseInt(closesParts[0], 10);
      let closesMinutes = Number.parseInt(closesParts[1], 10);
      const [timezoneOpens] = /[+|-].*/gi.exec(opens) || [];
      const [timezoneCloses] = /[+|-].*/gi.exec(closes) || [];

      if (timezoneOpens) {
        const change = Number.parseInt(timezoneOpens, 10);
        const [changeHours, changeMinutes] = timezoneOpens.split(':');
        opensHours =
            change > 0
                ? opensHours + (Number.parseInt(changeHours, 10) + this.offset)
                : opensHours - (Number.parseInt(changeHours, 10) + this.offset);
        opensMinutes =
            change > 0
                ? opensMinutes + Number.parseInt(changeMinutes, 10)
                : opensMinutes - Number.parseInt(changeMinutes, 10);
      }

      if (timezoneCloses) {
        const change = Number.parseInt(timezoneCloses, 10);
        const [changeHours, changeMinutes] = timezoneCloses.split(':');
        closesHours =
            change > 0
                ? closesHours + (Number.parseInt(changeHours, 10) + this.offset)
                : closesHours - (Number.parseInt(changeHours, 10) + this.offset);
        closesMinutes =
            change > 0
                ? closesMinutes + Number.parseInt(changeMinutes, 10)
                : closesMinutes - Number.parseInt(changeMinutes, 10);
      }

      return {
        dayOfWeek: this.dayOfWeekMap[`http://schema.org/${dayOfWeek}`] || dayOfWeek,
        opens: {
          hour: opensHours,
          minute: opensMinutes,
        },
        closes: {
          hour: closesHours,
          minute: closesMinutes,
        },
        ...(validFrom ? {validFrom: dayjs(validFrom)} : {}),
        ...(validThrough ? {validThrough: dayjs(validThrough)} : {}),
      };
    },

    getOpeningHoursForDay(day) {
      const days = compact(
          flatten(
              // return default openingHours day first
              ['default', 'special'].map((ohType) => {
                const typedOpeningHours = this[`${ohType}FilteredOpeningHours`];
                if (typedOpeningHours && typedOpeningHours.length) {
                  const found = typedOpeningHours[0].find((d) => d.dayOfWeek === day);
                  if (found) {
                    found.source = ohType;
                    return found;
                  }
                }
                return null;
              })
          )
      );

      const dayOpen = days.find((d) => !d.closed);
      return dayOpen || days[0] || false;
    },

    getDayOfWeekClasses(rangeGroup) {
      return rangeGroup
          .map((group) => group.map((day) => this.weekClass(day.dayOfWeek)).join(' '))
          .join(' ');
    },

    getFirstGroupItemProp(rangeGroup, prop) {
      const [firstGroup] = rangeGroup;
      const [firstItem] = firstGroup;
      return firstItem[prop];
    },

    generateNames(group) {
      // dont short names when only single entry
      if (group.length === 1 && group[0].length === 1) {
        return group.map((g) => g[0].dayOfWeek)[0];
      }

      // short names and add the dash
      return group
          .map((g) => {
            // split data in to sections: regular days and holidays
            const regularDaysAndHolidays = partition(g, (day) => {
              return day.dayOfWeek !== this.$t('components.openingHours.dayOfWeek.publicHolidays');
            });

            const [first] = g;
            const holiday = regularDaysAndHolidays[1].length
                ? `, ${this.$t('components.openingHours.dayOfWeek.publicHolidays')}`
                : '';

            const regularDays = regularDaysAndHolidays[0];

            if (regularDays.length > 1) {
              return `${regularDays[0].short}-${regularDays[regularDays.length - 1].short}` + holiday;
            }
            return (
                first.short +
                (first.short === this.$t('components.openingHours.dayOfWeek.publicHolidays')
                        ? ''
                        : holiday
                ).toString()
            );
          })
          .join(', ');
    },

    groupByIndex(group) {
      if (group.length === 1) {
        return [group];
      }

      let previousRow = [];
      const gr = [];

      for (let i = 0, l = group.length; i < l; i++) {
        const day = group[i];

        if (i - 1 < 0) {
          previousRow.push(day);
          // eslint-disable-next-line
          continue;
        }

        const previousDay = group[i - 1];
        const previousIndex = day.index - 1;
        const isPrevious =
            (previousIndex < 0 ? this.daysOfWeek.length + previousIndex : previousIndex) ===
            previousDay.index;

        /* if (previousIndex === 0 || previousIndex === 5) {
            // artificial break for weekends
            isPrevious = false;
          } */

        if (!isPrevious) {
          gr.push(previousRow);
          previousRow = [];
        }

        previousRow.push(day);

        if (i + 1 === group.length) {
          gr.push(previousRow);
          previousRow = [];
        }
      }

      return gr;
    },

    isValidRange(range) {
      return (
          (!range.from || dayjs().isSameOrAfter(range.from, 'day')) &&
          (!range.through || dayjs().isSameOrBefore(range.through, 'day'))
      );
    },

    parseDate(dateString) {
      return dayjs(dateString).locale('de').format('dddd, L');
    },

    dayIsBefore(date) {
      return dayjs().isBefore(date, 'day');
    },

    weekClass(dayOfWeek) {
      let dayIndicator = Object.values(this.dayOfWeekMap).indexOf(dayOfWeek) + 1;
      if (dayIndicator === 7) {
        dayIndicator = 0;
      }
      const isPublicHoliday = dayIndicator === 8;
      const isToday = dayIndicator === new Date().getDay();

      if (isPublicHoliday) {
        return 'day-public-holiday';
      }
      return `day-${dayIndicator}` + (isToday ? ' day-today' : '');
    },
  },
});
</script>

<style lang="scss" scoped>

.oh-wrapper {
  h2 {
    margin-top: 0;
  }

  h3 {
    margin-top: 1.375em;
  }

  ion-col, ion-row, ion-grid {
    padding: 0;
  }

  .openinghours-header {
    position: relative;

    .collapse-toggle {
      cursor: pointer;
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  .is-active-time {
    font-weight: bold;
  }

  .date-rangeset {
    &:first-child {
      margin-top: 12px;
    }

    color: var(--color);
    font-size: 0.875em;
    margin-bottom: 4px;
  }

  .valid-description {
    margin-top: 8px;
    padding-top: 4px;
    color: var(--ion-color-medium);
    border-top: 1px solid var(--ion-color-light);
  }

  .valid-description {
    line-height: 1em;
  }
}

</style>
