<template>
  <svg version="1.1" id="Ebene_1"
       xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 165.49 167.42"
       style="enable-background:new 0 0 165.49 167.42;" xml:space="preserve">
    <circle cx="84" cy="84" r="50" fill="#ffffff" class="contrast" />
		<path d="M81.35,24.46c-32.72,0-59.24,26.52-59.24,59.24c0,32.71,26.52,59.23,59.24,59.23s59.24-26.52,59.24-59.23
			C140.59,50.99,114.07,24.46,81.35,24.46z M110.9,119.75L61.04,86.17v30.78h-9.25V48.6h9.25v32.63l49.86-33.57V119.75z" fill="#000000"/>
</svg>
</template>

