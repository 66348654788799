<template>
  <no-wifi class="offline-tag" v-if="!isOnline"></no-wifi>
  <div class="map">
    <l-map v-if="showMap"
           :zoom="zoom"
           :bounds="bounds"
           :center="center"
           :options="mapOptions"
           @ready="mapReady"
           ref="leafletMap">
      <template v-for="(place, index) in mapLocations" :key="'marker'+index">
        <l-marker :lat-lng="place.position"
                  :icon="createMapIcon(place, index+1)"
        ></l-marker>
      </template>
      <l-control-zoom position="bottomright"/>
      <l-control-layers position="topleft"/>
      <l-tile-layer
          v-for="tileProvider in tileProviders"
          :key="tileProvider.name"
          :name="tileProvider.name"
          :visible="tileProvider.visible"
          :url="tileProvider.url"
          :attribution="tileProvider.attribution"
          layer-type="base"
      />
    </l-map>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { maxBy, minBy, debounce } from 'lodash-es';
import 'leaflet/dist/leaflet.css';
import "leaflet-gesture-handling/dist/leaflet-gesture-handling.css";
import { LMap, LTileLayer, LMarker, LControlZoom, LControlLayers } from '@vue-leaflet/vue-leaflet';
import { latLng, marker, icon, LatLngBounds } from 'leaflet/dist/leaflet-src.esm';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { GestureHandling } from 'leaflet-gesture-handling';

import { renderMarkerSVG } from "@/lib/map-marker";
import { mapState } from 'vuex';

import NoWifi from "@/assets/svg/no-wifi";

const getLat = (location) => {
  return location['schema:geo']['schema:latitude']
};

const getLong = (location) => {
  return location['schema:geo']['schema:longitude']
};

const getPolygonPoints = (polygon) => {
  const polyPoints = [];
  const latlng = [0, 0];
  const latlngStream = polygon.split(/[,; ]/).filter((number) => {
    const coord = number && Number.parseFloat(number);
    return coord && Number.isFinite(coord) && coord >= -180.0 && coord <= 180.0;
  });

  latlngStream.forEach((point, index) => {
    if (index % 2) {
      latlng[1] = Number.parseFloat(point.replace(/[,;]/, '').trim());
      polyPoints.push([latlng[0], latlng[1]]);
    } else {
      latlng[0] = Number.parseFloat(point.replace(/[,;]/, '').trim());
    }
  });
  return polyPoints;
};

const getWayPoints = (polyline) => {
  const wayPoints = [];
  polyline.split(' ').forEach((waypoint) => {
    const wayPtns = waypoint.split(',');
    wayPoints.push([Number.parseFloat(wayPtns[1]), Number.parseFloat(wayPtns[0])]);
  });
  return wayPoints;
};

export default defineComponent({
  name: 'MapDisplay',
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LControlZoom,
    LControlLayers,
    NoWifi,
  },

  props: {
    places: {
      type: Array,
      default: () => [],
    },
    useLabeledMarkers: {
      type: Boolean,
      default: false,
    }
  },

  setup(props) {
    const mapLocations = props.places
      .filter(place => place['schema:geo'])
      .map(place => {
        const geoPosition = [];
        if (place && place['schema:geo']) {
          if (
              place['schema:geo']['schema:latitude'] &&
              place['schema:geo']['schema:longitude']
          ) {
            geoPosition[0] = getLat(place);
            geoPosition[1] = getLong(place);
          } else if (place['schema:geo']['schema:polygon']) {
            const polyPoints = getPolygonPoints(place['schema:geo']['schema:polygon'])

            if (polyPoints.length) {
              const center = new LatLngBounds(polyPoints).getCenter();
              geoPosition[0] = Number.parseFloat(center.lat);
              geoPosition[1] = Number.parseFloat(center.lng);
            }
          } else if (place['schema:geo']['schema:line']) {
            const wayPoints = getWayPoints(place['schema:geo']['schema:line']);

            if (wayPoints.length) {
              geoPosition[0] = Number.parseFloat(this.waypoints[0][0]);
              geoPosition[1] = Number.parseFloat(this.waypoints[0][1]);
            }
          }
        }

        const [lat, lon] = geoPosition;

        if (lat && lon) {
          return {
            position: latLng(lat, lon),
            marker: marker(geoPosition),
            index: place.index || 0,
            lat,
            lon
          }
        }

        return undefined
      })
      .filter(location => location);

    let bounds = undefined
    let showMap = false;

    if (mapLocations?.length) {
      showMap = true;

      const north = maxBy(mapLocations, (o) => o.lat);
      const south = minBy(mapLocations, (o) => o.lat);
      const east = maxBy(mapLocations, (o) => o.lon);
      const west = minBy(mapLocations, (o) => o.lon);

      const southWest = [south.lat, west.lon];
      const northEast = [north.lat, east.lon];

      bounds = [southWest, northEast];
    }

    return { mapLocations, bounds, showMap }
  },

  data() {
    return {
      mapRef: null,
      center: this.places[0] ? latLng(
          getLat(this.places[0]), getLong(this.places[0])
      ) : [0, 0],
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      tileProviders: [
        {
          name: this.$t('components.map.overview'),
          visible: true,
          attribution:
              '&copy; <a target="_blank" rel="noopener noreferrer" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
          url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        },
        {
          name: this.$t('components.map.topology'),
          visible: false,
          url: 'https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png',
          attribution:
              'Map data: &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)',
        },
      ],
      attribution: '',
      zoom: 13,
      mapOptions: {
        zoomSnap: 0.5,
        zoomControl: false,
        gestureHandling: true,
      },
    };
  },

  computed: {
    ...mapState({
      isOnline(state) {
        return state.isOnline;
      },
    }),
  },

  methods: {
    createMapIcon(placeData, number) {
      const root = document.documentElement;
      const color = getComputedStyle(root).getPropertyValue("--ion-color-tour");
      const colorContrast = getComputedStyle(root).getPropertyValue("--ion-color-tour-contrast");

      let marker = renderMarkerSVG(0, color, colorContrast);

      if ((this.places.length > 1 && this.places.length <= 7) || this.useLabeledMarkers) {
        marker = renderMarkerSVG(placeData.index || number, color, colorContrast);
      }

      return icon({
        iconUrl: `data:image/svg+xml,${encodeURIComponent(marker)}`,
        iconSize: [32, 96], // size of the icon
        iconAnchor: [15, 64], // point of the icon which will correspond to marker's location
        popupAnchor: [-3, -76] // point from which the popup should open relative to the iconAnchor
      });
    },

    adjustMap() {
      this.mapRef?.invalidateSize(true);
      this.mapRef?.flyToBounds(this.bounds, {
        padding: [50, 50],
        maxZoom: 15,
        animate: true,
        duration: 0.6,
        easeLinearity: 0.4
      });

    },

    mapReady(ref) {
      this.mapRef = ref;
      const adjustMap = debounce(this.adjustMap, 500);
      adjustMap();
    }
  },

});
</script>

<style lang="scss" scoped>
.map {
  clear: both;
  width: 100%;
  height: 33vh;

  .leaflet-container {
    width: 100%;
    height: 100%;
  }

  @media(min-width: 768px) {
    height: 50vh;
  }

  ::v-deep(.leaflet-container) {
    z-index: 1;

    .leaflet-control-layers:not(.leaflet-control-layers-expanded) {
      border-radius: 100%;
    }

    .leaflet-control-zoom {
      border-radius: 5000px;

      a {
        width: 35px;
        height: 35px;
        line-height: 35px;
        color: var(--ion-color-tour);

        &:first-child {
          border-top-left-radius: 5000px;
          border-top-right-radius: 5000px;
        }

        &:last-child {
          border-bottom-left-radius: 5000px;
          border-bottom-right-radius: 5000px;
        }
      }
    }
  }
}

.offline-tag {
  background: var(--ion-color-tour);
  display: inline;
  padding: 0.15em;
  float: right;
  color: #FFF;
}
</style>
