<template>
  <div class="place-selection-wrapper" :class="{ 'inverted': inverted }">
    <ion-grid class="place-selection ion-padding-horizontal">
      <ion-row class="ion-align-items-center ripple-parent">
        <div class="marker-icon">
          <svg xmlns="http://www.w3.org/2000/svg" version="1.1" x="0px" y="0px" viewBox="0 0 80 79.999997" xml:space="preserve" >
            <g transform="matrix(1.2294796,0,0,1.2294796,-40.881784,-11.558971)">
              <path class="marker-shadow" d="m 64.83,72.59 c 9.63,0 17.43,-1.24 17.43,-2.77 0,-1.53 -7.8,-2.77 -17.43,-2.77 -9.63,0 -17.42,1.24 -17.42,2.77 0,1.53 7.8,2.77 17.42,2.77"/>
            </g>
            <path class="marker-body" d="M 63.255135,25.866386 C 63.390377,12.612597 52.755379,1.7562924 39.50159,1.6210497 c -0.159833,0 -0.307371,0 -0.467203,0 C 25.510112,1.7439976 14.567744,12.686365 14.444795,26.210641 c -0.03688,4.266294 1.057353,8.471114 3.184353,12.171847 h -0.06147 L 38.751608,74.283292 59.898657,38.25954 h -0.08607 c 2.225358,-3.749912 3.417953,-8.028501 3.442543,-12.393154"
            />
          </svg>
          <span class="index-label">{{ index }}</span>
        </div>
        <ion-col class="ion-text-center place-label">
          <h4>{{ title }}</h4>
        </ion-col>
        <ion-ripple-effect></ion-ripple-effect>
      </ion-row>
    </ion-grid>
  </div>
</template>

<script>
import { IonGrid, IonRow, IonCol, IonRippleEffect } from '@ionic/vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'PlaceButton',
  components: {
    IonGrid,
    IonRow,
    IonCol,
    IonRippleEffect
  },
  props: {
    inverted: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      required: true
    },
    routerLink: {
      type: String,
      required: true,
    },
    desc: {
      type: String,
      default: ''
    },
    index: {
      type: Number,
      required: true
    }
  },
  data() {
    return {}
  }
});
</script>

<style lang="scss" scoped>
.place-selection-wrapper {
  cursor: pointer;

  .place-selection {
    background: var(--ion-color-tour);
    border-radius: 40px;
    overflow: hidden;

    h4 {
      color: var(--ion-color-tour-contrast);
      margin: 0.25em 0 0.25em;
      padding-left: 40px;
    }

    .desc {
      color: var(--ion-color-tour-contrast);
    }

    ion-row {
      min-height: 60px;
    }

    .marker-icon {
      width: 48px;
      height: 48px;
      position: absolute;
      text-align: center;

      .index-label {
        font-weight: bold;
        color: var(--ion-color-tour);
        font-size: 24px;
        position: relative;
        line-height: 28px;
      }

      svg {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;

        .marker-shadow {
          opacity: 0.47;
          fill: var(--ion-color-tour-contrast);
        }
        .marker-body {
          fill: var(--ion-color-tour-contrast);
        }
      }
    }
  }

  &.inverted {
    .place-selection {
      background: var(--ion-color-tour-contrast);
      box-shadow: 0 0 12px rgba(0,0,0,.33);

      h4 {
        color: var(--ion-color-tour);
      }

      .desc {
        color: var(--ion-color-tour);
      }

      .marker-icon {
        .index-label {
          color: var(--ion-color-tour-contrast);
        }

        .marker-shadow {
          fill: var(--ion-color-tour);
        }
        .marker-body {
          fill: var(--ion-color-tour);
        }
      }
    }
  }
}


</style>
