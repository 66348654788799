<template>

  <div class="slider-wrapper">
    <template v-if="displayImages && displayImages.length > 1">
      <ion-slides class="slides" ref="slides" pager="true"
                  :options="slideOpts"
                  @ionSlideDidChange="onSlideChanged()"
                  @ionSlideReachEnd="onSlideChanged()"
                  @ionSlideWillChange="onBeforeSlideChanged()">
        <ion-slide v-for="(content, index) in displayImages" :key="'image'+index">
          <div class="slide">
            <img :src="media[content.src]" :alt="content.caption || content.title"/>
          </div>
        </ion-slide>
      </ion-slides>
      <div class="controls ion-text-center" @click.stop="preventClick($event)">
        <ion-button class="prev"
                    @click="prevSlide"
                    role="button"
                    :aria-label="$t('components.contentSlide.previous')">
          <slide-next-icon class="rotate-180"/>
        </ion-button>
        <ion-button class="next"
                    @click="nextSlide"
                    role="button"
                    :aria-label="$t('components.contentSlide.next')">
          <slide-next-icon />
        </ion-button>
      </div>
    </template>
    <template v-else-if="displayImages && displayImages[0] && displayImages[0].src">
      <img :src="media[displayImages[0].src]"
           :alt="displayImages[currentSlide].caption || displayImages[currentSlide].title"
           class="single-image"/>
    </template>
  </div>

  <div v-if="displayImages[currentSlide] && (displayImages[currentSlide].title || displayImages[currentSlide].caption)"
       class="ion-margin ion-text-center text-wrapper">
    <ion-text v-if="displayImages[currentSlide].title" color="tour">
      <h1>{{  displayImages[currentSlide].title }}</h1>
    </ion-text>
    <p v-if="displayImages[currentSlide].caption"
       v-html="displayImages[currentSlide].caption" class="preline-text"></p>
  </div>
  <div v-else-if="title || description"
       class="ion-margin ion-text-center text-wrapper">
    <ion-text v-if="title" color="tour">
      <h1>{{ title }}</h1>
    </ion-text>
    <p v-if="description"
       v-html="description" class="preline-text"></p>
  </div>

</template>

<script>
import {defineComponent, ref} from 'vue';
import { IonSlides, IonSlide, IonButton, IonText } from "@ionic/vue"
import SlideNextIcon from '@/assets/svg/slideNext.vue';
import { mapState } from 'vuex';

export default defineComponent({
  name: 'ContentSlider',

  components: {
    IonButton,
    IonSlides,
    IonSlide,
    SlideNextIcon,
    IonText,
  },

  props: {
    contents: {
      type: Array,
      required: true
    },
    showDetails: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    }
  },

  setup() {
    const slides = ref(null);

    // https://swiperjs.com/swiper-api#methods-and-properties
    const slideOpts = {
      initialSlide: 0,
      speed: 400,
      autoHeight: true,
      centeredSlides: true,
      loop: true,
      shortSwipes: false,
    };

    const prevSlide = async () => {
      const swiper = await slides?.value?.$el.getSwiper();
      await swiper.slidePrev();
    };

    const nextSlide = async () => {
      const swiper = await slides?.value?.$el.getSwiper();
      await swiper.slideNext();
    };

    return { slideOpts, slides, prevSlide, nextSlide }
  },

  async mounted() {
    this.slider = await this.$refs.slides?.$el.getSwiper();
    // this.slider.autoplay.start();
  },

  computed: {
    ...mapState(['media']),
  },

  data() {
    return {
      currentSlide: 0,
      displayImages: this.transformImageData(this.contents)
    };
  },

  methods: {
    preventClick(event) {
      event.stopPropagation();
      event.preventDefault();
    },

    onBeforeSlideChanged() {
      const activeBullet = document.querySelector('.swiper-pagination-bullet-active');
      if (activeBullet) {
        document.querySelectorAll('.prev-level-1, .prev-level-2').forEach((el) => {
          el.classList.remove('prev-level-1');
          el.classList.remove('prev-level-2');
        });

        activeBullet.previousElementSibling?.classList.add('prev-level-1');
        activeBullet.previousElementSibling?.previousElementSibling?.classList.add('prev-level-2');
      }
    },

    onSlideChanged() {
      this.currentSlide = this.slider?.realIndex || 0;
    },

    transformImageData(contents) {
      let images = [];
      if (contents) {
        images = Array.isArray(contents) ?  contents : [contents];
      }

      if (images && images.length) {
        return (
            images.map((image) => {
              let imageData;

              if (typeof image === 'string' && image.substr(0, 4) === 'http') {
                imageData = {
                  'schema:url': image,
                };
              } else {
                imageData = image;
              }

              const url = imageData['schema:url'] || imageData['schema:thumbnailUrl'];
              if (!url) {
                return false;
              }

              const caption = imageData['schema:description'] || '';
              const title = imageData['schema:name'] || '';

              return {
                type: 'image',
                src: url,
                caption,
                title,
              };
            }) || []
        );
      }
      return [];
    },
  }
});
</script>

<style lang="scss">
.slider-wrapper {
  position: relative;

  .single-image {
    min-width: 100%;
    height: auto;
  }

  ion-button {
    &::part(native) {
      width: 48px;
      height: 48px;
      border-radius: 24px;
      background: white;
    }

    svg path {
      stroke: var(--ion-color-tour);
    }
  }

  .controls {
    width: 0;
    height: 100%;

    .next, .prev {
      position: absolute;
      z-index: 1;
      top: 0;
      width: 80px;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 16px;

      ion-button:hover {
        &::part(.native) {
          background-color: white;
        }
      }

      svg {
        max-width: 16px !important;
        height: auto;
      }
    }

    .prev {
      left: 0px;
    }

    .next {
      margin-left: 100%;
      left: -80px;
    }
  }

  .swiper-wrapper {
    .slide {
      width: 100%;

      img {
        min-width: 100%;
      }
    }
  }

  .swiper-pagination-bullets {
    bottom: 16px;

    .swiper-pagination-bullet {
      width: 32px;
      height: 6px;
      border-radius: 3px;
      position: relative;
      background: white;
      overflow: hidden;
      opacity: 1 !important;
      z-index: 1;

      &:after {
        content: '';
        position: absolute;
        background: rgba(var(--ion-color-tour-rgb), 0.3);
        width: 110%;
        height: 100%;
        top: 0;
        left: -2px;
        z-index: 1;
      }

      /** active bullet predecessors **/
      &.prev-level-2 {
        &:after {
          background: rgba(var(--ion-color-tour-rgb), 0.5);
        }
      }

      &.prev-level-1 {
        &:after {
          background: rgba(var(--ion-color-tour-rgb), 0.7);
        }
      }

      &-active {
        background: none;

        &:after {
          background: var(--ion-color-tour);
        }

        /** active bullet successors **/
        + .swiper-pagination-bullet {
          &:after {
            background: rgba(var(--ion-color-tour-rgb), 0.7);
          }

          & + .swiper-pagination-bullet {
            &:after {
              background: rgba(var(--ion-color-tour-rgb), 0.5);
            }
          }
        }
      }
    }
  }

  .rotate-180 {
    transform: rotate(180deg);
  }
}

.text-wrapper {
  padding-bottom: 1px;
}
</style>
