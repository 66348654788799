
import {defineComponent} from "vue";
import PlaceButton from '@/components/place-button.vue';

export default defineComponent ({
  name: "TourPoiList",

  components: {
    PlaceButton
  },

  props: {
    regionData: {
      type: Object,
      default: null
    },
    tourData: {
      type: Object,
      default: null
    },
  },

  computed: {
    tourId(): string {
      return this.$route.params.tour?.toString();
    },
  },

  methods: {
    // TODO fix ref for Video and Audio Elements
    navigateAndDispose(url: string) {
      if (this.$refs.tourteaser) {
        (this.$refs.tourteaser as any).dispose();
      }
      if (this.$refs.touraudio) {
        (this.$refs.touraudio as any).dispose();
      }
      if (this.$refs.tourmusic) {
        (this.$refs.tourmusic as any).dispose();
      }
      this.$router.push({
        path: url,
      });
    }
  }
});
