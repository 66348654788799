<template>
  <section class="ion-margin-bottom ion-padding"
           v-if="certificationStatus">
    <div class="ion-padding ion-text-center">
      <ion-text color="tour">
        <h2 class="ion-text-uppercase">{{ widgetContent['schema:name'] || $t('placeDetails.accessibilityInfoHeader') }}</h2>
      </ion-text>
    </div>
    <accessibility-info :data="widgetContent"></accessibility-info>
  </section>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import {IonText} from "@ionic/vue";
import AccessibilityInfo from "@/components/accessibility-info.vue";

export default defineComponent ({
  name: "AccessibilityWidget",

  components: {
    IonText,
    AccessibilityInfo
  },

  props: {
    widgetContent: {
      type: Object,
      default: null
    },
  },

  computed: {
    certificationStatus(): boolean {
      return (
        this.widgetContent &&
        this.widgetContent['thuecat:accessibilitySpecification'] &&
        this.widgetContent['thuecat:accessibilitySpecification']['thuecat:accessibilityCertification'] &&
        this.widgetContent['thuecat:accessibilitySpecification']['thuecat:accessibilityCertification']['thuecat:accessibilityCertificationStatus'] &&
        (
          ['info, checked']
            .indexOf(
              this.widgetContent['thuecat:accessibilitySpecification']['thuecat:accessibilityCertification']['thuecat:accessibilityCertificationStatus']
            ) >= -1
        )
      );
    }
  }
});
</script>

<style scoped lang="scss">

</style>
