<template>
  <svg
      xmlns:svg="http://www.w3.org/2000/svg"
      xmlns="http://www.w3.org/2000/svg"
      width="48mm"
      height="48mm"
      viewBox="0 0 48 48"
      version="1.1">

      <path
          style="stroke-width:0.320744"
          d="M 23.999998,5 C 13.505736,5 5,13.506457 5,23.998395 5,34.493542 13.505736,43 23.999998,43 34.494257,43 43,34.493542 43,23.998395 43,13.509665 34.494257,5 23.999998,5 Z"
          id="path897" fill="#000000" />
      <path
          d="M 15.479337,33.940773 H 21.15978 V 14.059226 H 15.479337 Z M 26.84022,14.059226 v 19.881547 h 5.680443 V 14.059226 Z"
          id="path2607"
          class="contrast"
          fill="#ffffff"
      />
  </svg>
</template>
