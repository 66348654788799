
import {defineComponent} from "vue";
import { IonText } from "@ionic/vue"
import AudioPlayer from '@/components/audio-player.vue';

export default defineComponent ({
  name: "AudioWidget",

  components: {
    IonText,
    AudioPlayer,
  },

  props: {
    widgetContent: {
      type: Object,
      default: null
    }
  },

  computed: {
    sources(): any {
      const data: any = {
        ...this.widgetContent
      };

      const audioItems = this.widgetContent['schema:audio']
        ? (Array.isArray(this.widgetContent['schema:audio'])
          ? this.widgetContent['schema:audio']
          : [this.widgetContent['schema:audio']])
        : []

      if (audioItems.length) {
        data.audioObjects = audioItems;
      }

      return data;
    }
  }
});
