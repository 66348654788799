<template>
  <section class="opening-hours box-widget white ion-margin-top" v-if="widgetContent['schema:openingHoursSpecification']">
    <opening-hours :data="widgetContent"></opening-hours>
  </section>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import OpeningHours from "@/components/opening-hours.vue";

export default defineComponent ({
  name: "OpeningHoursWidget",

  components: {
    OpeningHours
  },

  props: {
    widgetContent: {
      type: Object,
      default: null
    },
  },
});
</script>

<style scoped lang="scss">
.opening-hours ::v-deep(.oh-wrapper) {
  color: var(--ion-color-tour);
}
</style>
