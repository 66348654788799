<template>
  <basic-page-layout :padding="true">

    <div class="overlay-top" slot="fixed">
      <ion-button @click="dismiss"
                  color="tour"
                  class="ion-text-capitalize"
                  shape="round"
                  expand="block"
      >{{ $t('components.audioPlayer.transcriptGoBack') }}</ion-button>
    </div>

    <div class="content-wrapper">
      <ion-text color="tour" class="ion-text-center">
        <h2>{{ $t('components.audioPlayer.transcriptTitle') }}</h2>
      </ion-text>
      <div class="preline-text" v-html="content"></div>
    </div>

  </basic-page-layout>
</template>

<script>
import { defineComponent } from 'vue';
import { IonButton, IonText, modalController } from '@ionic/vue';
import BasicPageLayout from '@/views/basic-page-layout.vue'

export default defineComponent({
  name: 'TranscriptModalView',
  emits: [ "dismiss" ],
  components: {
    IonText,
    IonButton,
    BasicPageLayout
  },

  props: {
    title: { type: String, default: '' },
    content: { type: String, default: '' },
    colorSet: { type: Object }
  },

  setup() {
    return {
      modalCtrl: modalController,
    }
  },

  methods: {
    dismiss() {
      this.modalCtrl.dismiss();
    }
  }
});
</script>

<style lang="scss" scoped>
.overlay-top {
  top: 0px;
  width: 100%;
  display: flex;
  justify-content: space-around;

  ion-button {
    margin: 0;

    &::part(native) {
      border-radius: 0 0 8px 8px;
    }
  }
}

.content-wrapper {
  padding-top: 32px;
}
</style>
