
import {defineComponent} from "vue";
import {IonText} from "@ionic/vue";
import AccessibilityInfo from "@/components/accessibility-info.vue";

export default defineComponent ({
  name: "AccessibilityWidget",

  components: {
    IonText,
    AccessibilityInfo
  },

  props: {
    widgetContent: {
      type: Object,
      default: null
    },
  },

  computed: {
    certificationStatus(): boolean {
      return (
        this.widgetContent &&
        this.widgetContent['thuecat:accessibilitySpecification'] &&
        this.widgetContent['thuecat:accessibilitySpecification']['thuecat:accessibilityCertification'] &&
        this.widgetContent['thuecat:accessibilitySpecification']['thuecat:accessibilityCertification']['thuecat:accessibilityCertificationStatus'] &&
        (
          ['info, checked']
            .indexOf(
              this.widgetContent['thuecat:accessibilitySpecification']['thuecat:accessibilityCertification']['thuecat:accessibilityCertificationStatus']
            ) >= -1
        )
      );
    }
  }
});
