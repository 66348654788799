
import {defineComponent} from "vue";
import { IonText } from "@ionic/vue"
import VideoPlayer from '@/components/video-player.vue';

export default defineComponent ({
  name: "VideoWidget",

  components: {
    IonText,
    VideoPlayer,
  },

  props: {
    widgetContent: {
      type: Object,
      default: null
    }
  },

  computed: {
    videoSrc(): string {
      if (this.widgetContent['schema:video'] && this.widgetContent['schema:video']['schema:url']) {
        return this.widgetContent['schema:video']['schema:url'];
      }
      return ''
    }
  }
});
