
import { defineComponent } from "vue";

import imgAInfo from '@/assets/images/accessibility/accessibility_info.png';
import imgAVeri from '@/assets/images/accessibility/accessibility_verified.png';
import blindPartialSymbol from '@/assets/images/accessibility/seal_blind_partial.png';
import blindFullSymbol from '@/assets/images/accessibility/seal_blind_full.png';
import deafPartialSymbol from '@/assets/images/accessibility/seal_deaf_partial.png';
import deafFullSymbol from '@/assets/images/accessibility/seal_deaf_full.png';
import mentalFullSymbol from '@/assets/images/accessibility/seal_mental_full.png';
import walkingPartialSymbol from '@/assets/images/accessibility/seal_walking_partial.png';
import walkingFullSymbol from '@/assets/images/accessibility/seal_walking_full.png';

export default defineComponent({
  name: "AccessibilityInfo",

  props: {
    data: {
      type: Object,
      required: true
    }
  },

  setup(props) {
    const node = {
      ...
          props.data
    }
    return { node, imgAInfo, imgAVeri }
  },

  computed: {
    symbolsMap(): any {
      return {
        'blind': {
          'partial': blindPartialSymbol,
          'full': blindFullSymbol,
        },
        'deaf': {
          'partial': deafPartialSymbol,
          'full': deafFullSymbol,
        },
        'walking': {
          'partial': walkingPartialSymbol,
          'full': walkingFullSymbol,
        },
        'mental': {
          'full': mentalFullSymbol,
        }
      }
    },

    certificationStatus(): string {
      if ( this.node['thuecat:accessibilitySpecification']
           && this.node['thuecat:accessibilitySpecification']['thuecat:accessibilityCertification']) {
        const status = this.node['thuecat:accessibilitySpecification']['thuecat:accessibilityCertification']['thuecat:accessibilityCertificationStatus'];

        switch (status) {
          default:
            return 'none';
          case 'thuecat:CertificationAccessibilityNoCertification':
            return 'none';
          case 'CertificationInformationAboutAccessibility':
            return 'info';
          case 'thuecat:AccessibilityChecked':
            return 'checked';
        }
      }
      return 'none'
    },

    accessibiltyMap(): any {
      const accMap: any[] = [
        {
          label: 'deaf',
          fullProp: 'certificationAccessibilityDeaf',
          partialProp: 'certificationAccessibilityPartiallyDeaf'
        },
        {
          label: 'blind',
          fullProp: 'certificationAccessibilityVisual',
          partialProp: 'certificationAccessibilityPartiallyVisual'
        },
        {
          label: 'walking',
          fullProp: 'certificationAccessibilityWheelchair',
          partialProp: 'certificationAccessibilityWalking'
        },
        {
          label: 'mental',
          fullProp: 'certificationAccessibilityMental',
        }
      ];

      if ( this.node['thuecat:accessibilitySpecification']
          && this.node['thuecat:accessibilitySpecification']['thuecat:accessibilityCertification'] ) {
        return accMap.map(item => {
          {
            const props = {
              ...item
            };

            return {
              label: item.label,
              valueFull: this.certMapping(this.node['thuecat:accessibilitySpecification']['thuecat:accessibilityCertification']['thuecat:'+props.fullProp]),
              valuePartial: this.certMapping(this.node['thuecat:accessibilitySpecification']['thuecat:accessibilityCertification']['thuecat:'+props.partialProp]),
            }
          }
        });
      }

      return {}
    }
  },

  methods: {
    certMapping(value: string) {
      switch(value) {
        default:
          return 'none';
        case 'thuecat:Full':
          return 'full';
        case 'thuecat:Info':
          return 'info'
      }
    }
  }

});
