
import {defineComponent} from "vue";
import { IonText } from "@ionic/vue"
import {mapState} from "vuex";

export default defineComponent ({
  name: "TextWidget",

  components: {
    IonText
  },

  props: {
    widgetContent: {
      type: Object,
      default: null
    }
  },

  setup(props) {
    const content: any = {};

    content.text = (props.widgetContent['schema:description'] || '').toString();
    content.title =  (props.widgetContent['schema:name'] || '').toString();

    if (props.widgetContent['schema:image'] && props.widgetContent['schema:image']['schema:url']) {
      content.imageSrc = props.widgetContent['schema:image']['schema:url'].toString();
    }

    return {content};
  },

  computed: {
    ...mapState(['media']),
  },

});
