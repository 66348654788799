<template>
  <div class="info-wrapper" v-if="listItems.length">
    <div class="ion-margin-bottom">
      <ion-text color="tour">
        <h2 class="ion-text-center ion-text-uppercase">{{ $t('components.visitorInformation.title') }}</h2>
      </ion-text>
    </div>

    <div v-for="(section, i) in listItems"
         :key="i"
         class="info-section">

        <div @click="changeValuesVisibility(section)"
             class="info-section-title"
             role="button"
             :aria-label='$t("components.visitorInformation.openSection", { section: section.label })'
        >
          <ion-text color="tour">
            <h3>{{ section.label }}</h3>
          </ion-text>
          <div :class="['collapse-toggle', { active: !section.hidden }]">
            <ChevronDown></ChevronDown>
          </div>
        </div>

        <div class="transition-anchor-slide">
          <transition-group name="slidedown">
            <ul class="info-section-content" v-if="!section.hidden">
              <li v-for="(item, index) in section.items"
                  :key="'listitem'+index">
                <span>{{ item }}</span>
              </li>
            </ul>
          </transition-group>
        </div>
    </div>

  </div>
</template>

<script>
import {defineComponent} from 'vue';
import { IonText} from '@ionic/vue';

import ChevronDown from "@/assets/svg/chevron-down";

export default defineComponent({
  name: 'VisitorInfo',

  components: {
    IonText,
    ChevronDown
  },

  props: {
    data: {
      type: Object,
      required: true
    },
    itemHideThreshold: {
      type: Number,
      default: 0,
    },
    properties: {
      type: Array,
    }
  },

  setup(props) {
    const node = {
      ...props.data
    };

    return {node}
  },

  created() {
    this.getItems();
  },

  data() {
    return {
      listItems: []
    }
  },

  methods: {
    changeValuesVisibility(item) {
      this.listItems[this.listItems.indexOf(item)].hidden = !item.hidden;
    },

    async getItems() {
      const headlines = {};

      // translate sections/props with i18n
      this.properties.map((p) => {
        const propName = (p.split(':'))[1];
        if (propName) {
          headlines[p] = this.$t(`components.visitorInformation.${propName}`);
        } else {
          headlines[p] = p
        }
      });

      this.listItems = this.properties.map((prop) => {
        const propName = (prop.split(':'))[1];
        let label = '';
        let items = []

        if (propName) {
          label = this.$t(`components.visitorInformation.${propName}.label`);
          items = Array.isArray(this.node[prop]) ? this.node[prop] : [this.node[prop]];
        } else {
          label = prop;
          items = this.node[prop];
        }

        return { label, items, hidden: true }
      });
    },
  },
})
</script>

<style lang="scss" scoped>
.info-wrapper {
  .info-section {
    .info-section-title {
      cursor: pointer;
      display: flex;
      justify-content: space-between;

      * {
        line-height: 24px;
      }

      h3 {
        margin:0;
        font-weight: 500;
        font-size: 20px;
      }

      .collapse-toggle {
        max-height: 24px;
        color: var(--ion-color-tour);
      }
    }

    .info-section-content {
      margin: 0.75em 0 0.5em;
      padding: 0;
      list-style-type: none;

      li {
        margin: 0;
        padding: 0 0 0.375em 0.125em;
      }
    }

    border-bottom: 1px solid var(--ion-color-light-shade);
    padding: 8px 0 4px;

    &:last-child {
      border-bottom: none;
    }
  }
}
</style>
