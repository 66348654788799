<template>
  <svg xmlns='http://www.w3.org/2000/svg' width='34' height='33' viewBox='0 0 34 33.289'>
  <g transform='translate(-244 -459)'>
    <g  transform='translate(245 460)'>
      <g transform='rotate(270 16 16)'>
        <path d='M15.644 32V0' fill='none' stroke='#ff0000' stroke-linecap='round' stroke-linejoin='round' stroke-width='4' class='navigation-icon'/>
        <path d='M0 14.933l14.507 16.5a1.73 1.73 0 0 0 2.276 0l14.506-16.5' fill='none' stroke='#ff0000' stroke-linecap='round' stroke-linejoin='round' stroke-width='4' class='navigation-icon'/>
        </g>
      </g>
    </g>
  </svg>
</template>
