<template>
  <section class="visitor-info box-widget white ion-margin-top" v-if="availableProps.length">
    <visitor-info :data="widgetContent" :properties="availableProps"></visitor-info>
  </section>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import VisitorInfo from '@/components/visitor-info.vue';

export default defineComponent ({
  name: "InfoWidget",

  components: {
    VisitorInfo
  },

  props: {
    widgetContent: {
      type: Object,
      default: null
    },
  },

  setup() {
    const properties: any[] =  [
      'thuecat:photography',
      'thuecat:gastro',
      'thuecat:sanitation',
      'thuecat:otherService',
      'thuecat:digitalOffer',
      'thuecat:guidedTour',
      'thuecat:entrance',
    ];

    return { properties }
  },

  computed: {
    availableProps(): any[] {
      return this.properties.filter((prop) => this.widgetContent[prop]);
    }
  }
});
</script>

<style scoped lang="scss">

</style>
