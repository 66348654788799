<template>
  <svg version="1.1" id="Ebene_1"
       xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 165.49 167.42"
       style="enable-background:new 0 0 165.49 167.42;" xml:space="preserve">
    <circle cx="84" cy="84" r="50" fill="#ffffff" class="contrast" />
		<path d="M86.47,24.46c-32.72,0-59.24,26.52-59.24,59.23c0,32.72,26.52,59.24,59.24,59.24c32.72,0,59.24-26.52,59.24-59.24
			C145.71,50.99,119.19,24.46,86.47,24.46z M116.03,118.8h-9.25V86.17l-49.86,33.58V47.66l49.86,33.58V50.45h9.25V118.8z" fill="#000000"/>
</svg>
</template>


