<template>
  <section class="tour-intro ion-padding ion-margin-bottom">
    <div class="tour-icon-wrapper" v-if="tourData['schema:logo'] && tourData['schema:logo']['schema:url']">
      <img :src="media[tourData['schema:logo']['schema:url']]" :style="{filter: logoFilter}">
    </div>
    <ion-text class="ion-text-center" color="tour">
      <h1>{{ widgetContent['schema:name'] }}</h1>
    </ion-text>
    <div v-html="widgetContent['schema:description']" class="ion-text-center preline-text"></div>
  </section>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import {IonText} from "@ionic/vue"

export default defineComponent({
  name: "TourHeaderWidget",

  components: {
    IonText
  },

  props: {
    widgetContent: {
      type: Object,
      default: null
    },

    tourData: {
      type: Object,
    },

    media: {
      type: Object,
      default: null
    }
  },

  computed: {
    logoFilter() {
      return ((this as any).tourData || {})._primaryColorFilter || '';
    }
  }
});
</script>

<style scoped lang="scss">
.tour-intro {
  .tour-icon-wrapper {
    width: 180px;
    height: 180px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  h1 {
    margin-top: 0;
    padding-bottom: 0.125em;
    border-bottom: 6px solid var(--ion-color-tour);
    margin-bottom: 0.75em;
    font-weight: bold;
    font-size: 1.625em;
  }
}
</style>
