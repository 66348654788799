
import { IonButton, modalController } from '@ionic/vue';
import { defineComponent } from 'vue';
import TranscriptModal from '@/components/modals/transcript.vue'

export default defineComponent({
  components: { IonButton },
  name: 'TranscriptTrigger',

  setup() {
    const data = { content: 'New Content' };
    return { data, modalCtrl: modalController }
  },

  props: {
    content: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    }
  },

  methods: {
    async openTranscript () {
      const modal = await modalController
          .create({
            component: TranscriptModal,
            componentProps: {
              content: this.content,
              title: this.title,
            },
          })
      return modal.present();
    },
  }
});
