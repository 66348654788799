<template>
  <section class="tour-places-ov ion-padding-horizontal" v-if="tourData['epapp:places']?.length" role="navigation">
    <div role="menu" :aria-label="$t('tourOverview.menuLabel')">
      <place-button v-for="(place, key) in tourData['epapp:places']"
                    :key="`place_${place['@id']}`"
                    :title="place['schema:name']"
                    :router-link="place.routerLink || ''"
                    :index="key+1"
                    role="menuitem"
                    :aria-label="place['schema:name']"
                    @click="navigateAndDispose(`/regions/${regionData.id}/tours/${tourId}/${place['@id']}`)"
                    class="ion-activatable ripple-parent">
      </place-button>
    </div>
  </section>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import PlaceButton from '@/components/place-button.vue';

export default defineComponent ({
  name: "TourPoiList",

  components: {
    PlaceButton
  },

  props: {
    regionData: {
      type: Object,
      default: null
    },
    tourData: {
      type: Object,
      default: null
    },
  },

  computed: {
    tourId(): string {
      return this.$route.params.tour?.toString();
    },
  },

  methods: {
    // TODO fix ref for Video and Audio Elements
    navigateAndDispose(url: string) {
      if (this.$refs.tourteaser) {
        (this.$refs.tourteaser as any).dispose();
      }
      if (this.$refs.touraudio) {
        (this.$refs.touraudio as any).dispose();
      }
      if (this.$refs.tourmusic) {
        (this.$refs.tourmusic as any).dispose();
      }
      this.$router.push({
        path: url,
      });
    }
  }
});
</script>

<style scoped lang="scss">
.tour-places-ov {
  padding-bottom: 16px;

  ::v-deep(.place-selection-wrapper) {
    position: relative;
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }

    .place-selection {
      z-index: 1;
      position: relative;
    }

    /*
    &::after {
      content: ' ';
      display: block;
      position: relative;
      left: 42px;
      border-left: 8px dotted #fff;
      min-height: 64px;
      top: -9px;
      max-width: 8px;
      z-index: 0;
    }

    &.inverted::after {
      min-height: 48px;
      max-height: 48px;
    }
    */

    &:last-child::after {
      display: none;
    }
  }
}
</style>
