<template>
  <svg version="1.1"
       xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 165.49 167.42"
       style="enable-background:new 0 0 165.49 167.42;" xml:space="preserve">
    <circle cx="84" cy="84" r="50" fill="#ffffff" class="contrast" />
			<path d="M33.38,100.01c1.27-4.09,1.27-8.01,0-12.04C32.03,89.55,32.02,98.31,33.38,100.01 M37.26,84.57
c-0.16,0.03-0.33,0.06-0.49,0.08c-1.22,6.21-1.38,12.41,0.25,18.6c1.43-5.5,1.7-11.04,0.61-16.62
C37.5,85.95,37.39,85.25,37.26,84.57 M40.96,104.91c1.69-1.92,1.65-20.54,0.01-22.1C39.48,84.52,39.44,102.8,40.96,104.91
 M46.56,94.04c-0.32-3.6-0.63-7.19-0.98-10.78c-0.03-0.27-0.4-0.52-0.62-0.78c-0.18,0.25-0.48,0.48-0.51,0.75
c-0.14,1.13-0.16,2.27-0.32,3.4c-0.88,5.94-0.36,11.86,0.31,17.78c0.03,0.31,0.35,0.57,0.54,0.86c0.2-0.28,0.56-0.54,0.59-0.84
C45.94,100.95,46.24,97.49,46.56,94.04 M47.56,94.03c0.26,3.5,0.51,7.01,0.82,10.52c0.03,0.3,0.45,0.57,0.69,0.85
c0.22-0.31,0.58-0.59,0.62-0.93c0.19-1.38,0.29-2.78,0.4-4.18c0.18-2.23,0.56-4.46,0.48-6.68c-0.11-3.17-0.54-6.33-0.88-9.5
c-0.04-0.33-0.41-0.61-0.63-0.91c-0.24,0.28-0.67,0.54-0.69,0.84C48.07,87.36,47.82,90.69,47.56,94.03 M51.93,86.14
c-0.06,0-0.12,0-0.18,0c0,3.49-0.08,6.97,0.03,10.46c0.08,2.68,0.37,5.37,0.63,8.05c0.03,0.32,0.43,0.6,0.66,0.9
c0.25-0.28,0.7-0.54,0.72-0.84c0.34-3.96,0.86-7.93,0.85-11.91c-0.03-4.96-0.5-9.92-0.83-14.88c-0.03-0.44-0.48-0.86-0.73-1.29
c-0.24,0.4-0.66,0.8-0.69,1.22C52.19,80.61,52.08,83.38,51.93,86.14 M58.4,84.34c-0.09,0-0.18,0-0.28,0
c0-2.69,0.01-5.37-0.01-8.07c-0.01-0.87-0.04-1.75-0.2-2.61c-0.07-0.34-0.5-0.61-0.77-0.91c-0.27,0.31-0.6,0.57-0.8,0.92
c-0.12,0.2-0.03,0.52-0.04,0.78c-0.1,2.96-0.13,5.92-0.32,8.86c-0.46,7.09-0.35,14.16,0.36,21.22c0.04,0.37,0.5,0.71,0.76,1.06
c0.29-0.32,0.76-0.61,0.82-0.97c0.2-1.19,0.27-2.4,0.35-3.61c0.18-2.87,0.43-5.74,0.46-8.61C58.76,89.71,58.52,87.02,58.4,84.34
 M62.94,84.28c-0.16,0-0.33,0-0.49,0c0-2.73,0.03-5.45-0.01-8.18c-0.01-1.4-0.07-2.81-0.22-4.2c-0.05-0.35-0.55-0.65-0.84-0.98
c-0.32,0.31-0.67,0.6-0.93,0.95c-0.11,0.14-0.03,0.44-0.04,0.67c-0.13,4.17-0.21,8.34-0.41,12.5c-0.31,6.45-0.14,12.88,0.45,19.3
c0.04,0.43,0.58,0.82,0.89,1.22c0.31-0.4,0.85-0.77,0.89-1.19c0.29-2.95,0.59-5.89,0.67-8.84
C63.01,91.78,62.94,88.03,62.94,84.28 M66.57,83.05c0-3.76,0-7.5,0-11.26c0-0.78,0.01-1.69-1.01-1.72
c-1.04-0.03-1.04,0.88-1.06,1.66c-0.02,1.36-0.06,2.73-0.09,4.09c-0.26,9.47-0.82,18.94,0.09,28.4c0.07,0.7,0.24,1.43,1.04,1.27
c0.4-0.07,0.94-0.77,0.98-1.23C67.21,97.21,67.4,90.14,66.57,83.05 M71.13,84.65c0-0.95,0.02-2.01-0.01-3.07
c-0.08-3.19-0.14-6.37-0.3-9.55c-0.02-0.48-0.4-1.01-0.78-1.35c-0.16-0.14-0.86,0.05-1.06,0.29c-0.25,0.31-0.34,0.82-0.35,1.26
c-0.08,1.93-0.14,3.87-0.14,5.8c-0.04,7.5-0.07,15.01-0.08,22.51c0,1.29,0.06,2.59,0.26,3.85c0.07,0.44,0.65,0.79,0.99,1.18
c0.39-0.35,0.8-0.67,1.13-1.05c0.12-0.14,0.08-0.44,0.09-0.67C71.31,97.49,71.67,91.13,71.13,84.65 M75.76,84.67
c-0.19-0.01-0.38-0.01-0.56-0.01c0-3.76,0.01-7.5-0.01-11.26c0-0.41,0.03-0.89-0.17-1.21c-0.22-0.37-0.68-0.8-1.04-0.8
c-0.36-0.01-0.88,0.41-1.04,0.78c-0.22,0.45-0.18,1.03-0.19,1.56c-0.09,9.85-0.84,19.7-0.03,29.55c0.05,0.53,0.03,1.1,0.24,1.55
c0.16,0.33,0.65,0.69,1.01,0.69c0.35,0.01,0.8-0.35,1.02-0.68c0.21-0.32,0.22-0.8,0.22-1.21C75.4,97.31,75.58,90.99,75.76,84.67
 M77.01,83.01c-0.19,0-0.38,0.01-0.57,0.01c0.18,7.01,0.37,14.01,0.56,21.02c0.02,0.86,0.27,1.53,1.25,1.54
c1,0.01,1.25-0.74,1.29-1.55c0.17-4.02,0.46-8.03,0.44-12.04c-0.05-7.65-0.27-15.31-0.44-22.96c-0.01-0.37-0.05-0.76-0.19-1.11
c-0.38-0.97-1.48-1.14-2.04-0.29c-0.23,0.35-0.28,0.86-0.28,1.29C77,73.61,77.01,78.31,77.01,83.01 M84.34,81.63
c-0.16,0-0.31,0-0.46,0c0-5,0-10.01-0.01-15.01c0-1.28-0.52-1.97-1.44-1.89c-1.16,0.1-1.29,0.98-1.3,1.91
c-0.03,2.16-0.1,4.32-0.12,6.48c-0.12,10.27-0.76,20.54,0.12,30.81c0.07,0.91,0.4,1.61,1.37,1.61c0.92,0.01,1.31-0.68,1.36-1.52
c0.17-2.64,0.41-5.3,0.45-7.94C84.39,91.26,84.34,86.44,84.34,81.63 M116.04,81.34c-0.04-0.24-0.14-0.72-0.22-1.21
c-2.21-13.67-16.46-21.89-29.41-16.97c-0.95,0.36-1.38,0.93-1.38,1.95c0.01,12.83,0,25.65,0.01,38.48c0,1.49,0.5,1.99,1.97,1.99
c11.3,0,22.62,0.01,33.92-0.02c1.12-0.01,2.27-0.18,3.36-0.46c5.68-1.48,9.48-7.01,8.99-13c-0.46-5.75-5.21-10.69-10.95-11.13
C120.31,80.81,118.24,81.19,116.04,81.34 M78.86,25.39h7.75c0.33,0.07,0.65,0.19,0.99,0.21c5.6,0.39,10.99,1.67,16.2,3.7
c25.22,9.85,40.45,35.42,36.7,62.48c-1.91,13.74-8.01,25.45-18.28,34.8c-13.52,12.3-29.53,17.25-47.62,14.86
c-13.71-1.82-25.31-8.04-34.71-18.19c-7.7-8.32-12.58-18.1-14.54-29.29c-0.37-2.12-0.61-4.25-0.91-6.38v-7.74
c0.18-1.42,0.32-2.85,0.53-4.26c2.06-13.68,8.08-25.31,18.23-34.71c8.35-7.74,18.16-12.62,29.4-14.57
C74.67,25.92,76.77,25.68,78.86,25.39"/>

</svg>
</template>

