<template>
  <section class="tour-offline-mode ion-margin-bottom">
    <offline-mode :region-data="regionData" :tour-data="tourData"></offline-mode>
  </section>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import OfflineMode from '@/components/offline-mode.vue';

export default defineComponent ({
  name: "TourOfflineModeWidget",

  components: {
    OfflineMode
  },

  props: {
    regionData: {
      type: Object,
      default: null
    },

    tourData: {
      type: Object,
      default: null
    }
  }
});
</script>

<style scoped lang="scss">

</style>
