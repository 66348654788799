<template>
  <svg version="1.1"
       xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 165.49 167.42"
       style="enable-background:new 0 0 165.49 167.42;" xml:space="preserve">
		<path d="M149.07,80.99c0-34.8-28.31-63.12-63.12-63.12c-34.8,0-63.12,28.31-63.12,63.12c0,12.06,3.34,23.56,9.69,33.6v10.21
			c0,6.71,5.44,12.15,12.15,12.15h15.01V88.34H44.68c-6.71,0-12.15,5.44-12.15,12.15v3.32c-3.06-7.14-4.65-14.85-4.65-22.83
        c0-32.02,26.05-58.07,58.07-58.07s58.07,26.05,58.07,58.07c0,7.5-1.49,14.84-4.25,21.69v-2.18c0-6.71-5.44-12.15-12.15-12.15
        H112.6v48.62h15.01c6.71,0,12.15-5.44,12.15-12.15V113.9C145.79,104.05,149.07,92.68,149.07,80.99z" fill="#000000"/>
  </svg>
</template>

