<template>
  <svg version="1.1"
       xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 165.49 167.42"
       style="enable-background:new 0 0 165.49 167.42;" xml:space="preserve">
    <circle cx="84" cy="84" r="50" fill="#ffffff" class="contrast" />
		<path d="M69.77,94.92c-6.65,0.09-13.82,0.91-20.89,2.59c-2.57,0.61-3.76,2.48-3.16,4.94c0.48,2.02,2.41,3,4.79,2.46
			c8.84-1.97,17.76-2.95,26.83-2.02c8.22,0.84,15.97,3.11,23.13,7.31c0.45,0.27,0.9,0.54,1.39,0.73c2.28,0.9,4.71-0.72,4.86-3.17
			c0.12-1.93-0.79-3.17-2.4-4.12C93.85,97.45,82.43,94.97,69.77,94.92 M69.65,85.41c7.65-0.03,15.14,1.03,22.48,3.17
			c5.22,1.52,10.17,3.65,14.87,6.39c2.91,1.7,6.13,0.39,6.9-2.72c0.55-2.21-0.35-4.3-2.47-5.56c-10.63-6.3-22.21-9.48-34.44-10.49
			c-5.62-0.46-11.26-0.56-16.84,0.27c-4.62,0.69-9.2,1.68-13.74,2.8c-2.62,0.64-3.83,3.4-3.04,6.02c0.73,2.42,3.25,3.71,5.83,2.95
			C55.88,86.25,62.7,85.38,69.65,85.41 M122.22,72.87c-0.03-2.95-1.11-4.71-3.26-5.92C107.11,60.28,94.2,57.4,80.8,56.27
			c-6.37-0.54-12.76-0.53-19.15-0.04c-6.22,0.47-12.34,1.46-18.33,3.22c-3.18,0.93-4.78,3.94-3.89,7.16
			c0.81,2.91,3.78,4.55,6.91,3.66c5.54-1.57,11.17-2.49,16.91-2.88c11.37-0.76,22.6,0.07,33.67,2.88
			c5.93,1.5,11.61,3.64,16.94,6.68C117.74,79.16,122.18,76.66,122.22,72.87 M83.63,142.02h-8.26c-0.35-0.08-0.69-0.18-1.04-0.24
			c-2.12-0.3-4.26-0.48-6.35-0.91c-13.07-2.69-24.1-9.01-32.71-19.21c-12.14-14.37-16.43-30.99-12.92-49.44
			c2.49-13.11,8.99-24.1,19.18-32.71c14.38-12.15,30.99-16.44,49.44-12.93c13.33,2.53,24.37,9.27,33.14,19.62
			c6.63,7.83,10.89,16.84,12.71,26.96c0.38,2.14,0.65,4.29,0.97,6.44v8.26c-0.08,0.35-0.18,0.69-0.23,1.04
			c-0.31,2.12-0.48,4.26-0.91,6.35c-2.73,13.24-9.25,24.27-19.55,33.03c-7.85,6.67-16.88,10.96-27.04,12.78
			C87.92,141.43,85.77,141.7,83.63,142.02" fill="#000000"/>
	</svg>
</template>