<template>
  <section class="ion-margin-vertical box-widget" v-if="content">

    <div class="ion-margin-bottom">
      <ion-text class="ion-text-center" color="tour">
        <h2 class="ion-text-uppercase">{{ content.title || $t('tourOverview.audioFeatureHeadline') }}</h2>
      </ion-text>
      <div v-if="content.imageSrc" class="ion-margin-vertical">
        <img :src="media[content.imageSrc]">
      </div>
      <div v-html="content.text" class="ion-text-center preline-text"></div>
    </div>

  </section>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import { IonText } from "@ionic/vue"
import {mapState} from "vuex";

export default defineComponent ({
  name: "TextWidget",

  components: {
    IonText
  },

  props: {
    widgetContent: {
      type: Object,
      default: null
    }
  },

  setup(props) {
    const content: any = {};

    content.text = (props.widgetContent['schema:description'] || '').toString();
    content.title =  (props.widgetContent['schema:name'] || '').toString();

    if (props.widgetContent['schema:image'] && props.widgetContent['schema:image']['schema:url']) {
      content.imageSrc = props.widgetContent['schema:image']['schema:url'].toString();
    }

    return {content};
  },

  computed: {
    ...mapState(['media']),
  },

});
</script>

<style scoped lang="scss">
</style>
