<template>
  <section class="tour-mobility ion-margin-vertical box-widget">
    <ion-text class="ion-text-center" color="tour">
      <h2 class="ion-text-uppercase">{{ widgetContent['schema:name'] || $t('tourOverview.mobilityHeadline') }}</h2>
    </ion-text>
    <div v-html="widgetContent['schema:description']" class="ion-text-center preline-text"></div>
    <div v-if="places.length" class="ion-margin-top">
        <map-display :places="places"
                     :use-labeled-markers="true"
                     :key="tourData.id + '_map'"></map-display>
    </div>
  </section>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import { IonText } from "@ionic/vue"
import MapDisplay from '@/components/map-display.vue';

export default defineComponent ({
  name: "TourMobilityWidget",

  components: {
    IonText,
    MapDisplay
  },

  props: {
    tourData: {
      type: Object,
      default: null
    },

    widgetContent: {
      type: Object,
      default: null
    }
  },

  data() {
    return {
      places: [],
    }
  },

  watch: {
    'tourData.id': {
      immediate: true,
      handler() {
          const places = [];
          if (this.tourData['epapp:places'] && this.tourData['epapp:places'].length) {
            this.tourData['epapp:places'].forEach((place, index) => {
              if (place['schema:geo']) {
                places.push({
                  'schema:geo': place['schema:geo'],
                  index: index+1
                })
              }
            })
          }
          this.places = places;
        }
      }
    }

});
</script>

<style scoped lang="scss">
.tour-mobility {
  h3 {
    margin-top: 0.25em;
  }

  .map {
    margin-top: 16px;
  }
}
</style>
