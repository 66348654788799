<template>
  <section class="travel-info box-widget ion-margin-top">
    <div class="ion-text-center">
      <ion-text color="tour">
        <h2 class="ion-text-uppercase">{{ widgetContent['schema:name'] || $t('placeDetails.travelInfoHeader') }}</h2>
      </ion-text>
      <div v-html="widgetContent['schema:description']" class="preline-text"></div>
    </div>
    <div class="rounded ion-margin-top" v-if="widgetContent['schema:address']">
      <address-display :data="widgetContent" :title="placeData['schema:name']"></address-display>
    </div>
    <div class="ion-margin-top">
      <map-display :places="[ widgetContent ]"></map-display>
    </div>
  </section>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import {IonText} from '@ionic/vue';
import MapDisplay from '@/components/map-display.vue';
import AddressDisplay from "@/components/address-display.vue";

export default defineComponent ({
  name: "LocationWidget",

  components: {
    IonText,
    MapDisplay,
    AddressDisplay,
  },

  props: {
    widgetContent: {
      type: Object,
      default: null
    },
    placeData: {
      type: Object,
      default: null
    },
  },
});
</script>

<style scoped lang="scss">

</style>
